//React Components
import React, { useState, useEffect } from 'react';

//API
import { MachinesFetchAPI } from '../../api/Machines';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';

//IMG 
import nada from '../img/icons/nada.png'


import vending_machine from '../img/icons/vending.png'
import ModalviewMachine from './ModalviewMachine';
import ModalOperator from './ModalOperator';

const VendingTable = ({ branchoffice_id, eventHandler, token, user }) => {
    const [machines, setMachines] = useState([]);

    //Get the machines by branch office
    useEffect(() => {
        getMachinesByBranchOffice()
    }, [eventHandler]);


    //Function to get the machines by branch office
    const getMachinesByBranchOffice = async () => {
        try {
            const response = await MachinesFetchAPI.getMachinesByBranchOffice(branchoffice_id, token);
            let data = response.data.data
            const filtervendingData = data.filter(machine => machine.machine_id === 7)
            setMachines(filtervendingData)
        } catch (err) {
            setMachines([])
        }
    }

    //Function to view the machines
    const viewMachines = () => {
        const list = machines.length > 0
            ? machines.map((machine, index) => (
                <Col sm="6" md="6" lg="4" xl="4" key={index}>
                    <ListGroup as="ol" className='m-1' >
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start rounded-4"
                        >
                            <img
                                className='imgitemsList m-1'
                                src={vending_machine}
                                alt=""
                            />
                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                <div className="fw-bold">{machine.machine_name}</div>
                                <div className='d-flex justify-constent-center align-items-center'>

                                </div>

                                <div className="">Tipo: <b>{machine.description}</b></div>

                                <div className='m-1'>
                                    <ModalviewMachine machine={machine}></ModalviewMachine>
                                    <ModalOperator machine={machine} token={token} branchoffice_id={branchoffice_id}></ModalOperator>

                                </div>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            ))
            : <>

                <div className="position-relative p-5 text-center text-muted bg-body border border-dashed rounded-5">
                    <img src={nada} alt="Vacio" />
                    <h2 className="text-body-emphasis fw-bold fs-1">Vacío</h2>
                    <p className="col-lg-6 mx-auto mb-4 fs-4">
              No se encuentran máquinas vending disponibles en este momento. Le sugerimos seleccionar o verificar una sucursal con disponibilidad.
                    </p>

                </div>

            </>



        return (list)
    }

    return (
        <>
            <div className='border rounded-3 p-sm-1 p-lg-4'>
                <div className="row  mx-1 mt-3 ">
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className=' fs-5 fw-bold text-left text-primary'>
                                Vendings
                                <span className='badge rounded-pill bg-primary-subtle text-primary border-primary shadow-sm rounded-pill mx-1'>
                                    {machines.length}
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className=" mt-3">
                    <Container>
                        <Row>
                            {viewMachines()}
                        </Row>
                    </Container>
                </div>
            </div>
        </>

    );
};

export default VendingTable;

