//Function to list the days of the week
const DaysList = () => {
    const daysData = [
        { value: "MON", label: 'Lunes' },
        { value: "TUE", label: 'Martes' },
        { value: "WED", label: 'Miercoles' },
        { value: "THU", label: 'Jueves' },
        { value: "FRI", label: 'Viernes' },
        { value: "SAT", label: 'Sabado' },
        { value: "SUN", label: 'Domingo' },
    ];

    return daysData;
};

export default DaysList;