import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//API 
import { CouponPosFetchAPI } from '../../../api/Coupon';
//Alerts 
import AlertError from '../../Alerts/ErrorAlert';

//Modal asing product cupon
const ModalAsingProductCupon = ({ showModal, setShowModal, groups, selectedCupon, setEventHandler }) => {

    //Function to add a product to the cupon
    const addProductToCupon = async (e) => {
        e.preventDefault();
        const product = e.target.product.value;
        try {
            let res = await CouponPosFetchAPI.addProductToCupon(selectedCupon, product, localStorage.getItem('x-access-token'));

            if (res.status === 200) {
                AlertError('Producto asignado correctamente', 'Exito', 'success')
                setEventHandler(1)
            }
        } catch (err) {
            console.log(err)
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al asignar el producto al cupon', 'error', 'error')
        }


    }
    return (
        <div>
            <Modal
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asignar producto a cupón</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addProductToCupon}>
                        <Form.Group controlId="product">
                            <Form.Label>Producto</Form.Label>
                            <Form.Select>
                                <option value={null}>Seleccione producto...</option>
                                {groups.map((group) => (
                                    <option key={"group"+group.group_id} value={group.group_id}>{group.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
                            <Button variant="success" type='submit'>Guardar</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>


            </Modal>
        </div>
    );
}

export default ModalAsingProductCupon;
