//React components
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//Images
import defaultImage from "../../../assets/img/default.png";
//Utils
import { RenderIf } from '../../utils/RenderIf';
//API
import { CouponPosFetchAPI } from '../../../api/Coupon';
//Alerts
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';

// Modal update cupons 
const ModalUpdateCupon = ({ cupon, token, setEventHandler, showModal, setShowModal }) => {

    //States
    const [detailCupon, setDetailCupon] = useState({ maxDiscount: 0, minAmount: 0, expirationDate: "No definido",  img: "", name: "", description: "", discount_value: 0, discount_type: "FIXED", type: "PRODUCT", conditions: [], categories: [] })
    const [imgpreview, setImgPreview] = useState(defaultImage);
    const [cuponType, setCuponType] = useState('ORDER');
    const [showDateLimit, setShowDateLimit] = useState(false);
    const [discount_type, setDiscountType] = useState('PERCENTAGE');
    const [fileInputRef, setFileInputRef] = useState(null);
    const fileInput = useRef(null);

    //Function to get the detail of the cupon
    const getDetailCupon = async (cupon) => {
        try {
            const response = await CouponPosFetchAPI.getCupon(cupon, localStorage.getItem('x-access-token'));
            //We get the max discount that this cupon has
            let maxDiscount = response.data.data.conditions.filter((condition) => condition.condition_type === "max_discount")
            //We get the min amount that this cupon has
            let minAmount = response.data.data.conditions.filter((condition) => condition.condition_type === "min_order_value")
            //We get the expiration date of the cupon
            let expirationDate = response.data.data.conditions.filter((condition) => condition.condition_type === "expiration_date")

            console.log(response.data.data)
            //We set the info
            setDetailCupon({
                ...response.data.data,
                maxDiscount: maxDiscount && maxDiscount.length > 0 ? maxDiscount[0].condition_value : "No definido",
                minAmount: minAmount && minAmount.length > 0 ? minAmount[0].condition_value : "No definido",
                expirationDate: expirationDate && expirationDate.length > 0 ? expirationDate[0].condition_value : "No definido"
            }); 

            setImgPreview(response.data.data.img ? response.data.data.img : defaultImage);
        } catch (err) {

        }
    }


    // Function to trigger file input click
    const handleClick = () => {
        fileInput.current.click();
        setFileInputRef(null)
    };

    // Handle image preview update
    const changeImage = (e) => {
        console.log(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
        setFileInputRef(e.target.files[0]);
    };



    // Handle form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Previene el comportamiento por defecto del formulario

        // Recopilación de los datos del formulario
        const formData = new FormData();
        // Agregamos los demás campos del formulario
        const couponData = {
            name: e.target.name.value,
            type: cuponType,
            discount_type: discount_type,
            discount_value: e.target.discount_value.value,
            description: e.target.description.value,
            min_order_value: cuponType === "ORDER" ? e.target.min_order_value.value : null,
            max_discount: cuponType === "ORDER" ? e.target.max_discount.value : null,
            conditions: [],
        };


        if (e.target.usage_limit !== undefined || null) {
            couponData.usage_limit = e.target.usage_limit.value;
        } else {
            couponData.usage_limit = 0;
        }

        if (e.target.code_count !== undefined || null) {
            couponData.code_count = e.target.code_count.value;
        } else {
            couponData.code_count = 0;
        }

        if (showDateLimit) {
            couponData.conditions.push({ type: 'expiration_date', value: e.target.expiration_date.value });
        }

        if (couponData.min_order_value) {
            couponData.conditions.push({ type: 'min_order_value', value: couponData.min_order_value });
        }

        if (couponData.max_discount) {
            couponData.conditions.push({ type: 'max_discount', value: couponData.max_discount });
        }

        //We add the coupon data to the form data
        formData.append("name", couponData.name);
        formData.append("type", couponData.type);
        formData.append("discount_type", couponData.discount_type);
        formData.append("discount_value", couponData.discount_value);
        formData.append("description", couponData.description);
        formData.append("conditions", JSON.stringify(couponData.conditions));
        formData.append("min_order_value", couponData.min_order_value);
        formData.append("max_discount", couponData.max_discount);

        try {
            //We send the data to the API
            await CouponPosFetchAPI.updateCupon(cupon, formData, token);
            //We close the modal
            setShowModal(false);
            //We show a success message
            SuccessAlert("Cupón actualizado exitosamente");
            //We update the event handler
            setEventHandler(1);
        } catch (err) {
            console.error(err);
            //We show an error message
            ErrorAlert("Error al actualizar el cupón", "Inténtalo de nuevo", "error");
        }
    };
    //UseEffect
    useEffect(() => {
        if (showModal) {
            getDetailCupon(cupon)
        }
    }, [showModal]);

    return (
        <>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgPreview(defaultImage),  setDiscountType("PERCENTAGE"))}
                size='xl'
                centered
            >
                <Modal.Body>
                    <div className='text-center p-2'>
                        <h2 className='h4 fw-bold text-primary'>
                            Actualizar cupón
                        </h2>
                    </div>
                    <Form.Label className='text-black fw-semibold'>
                        Imagen de cupón
                    </Form.Label>
                    <div className='border rounded-4' onClick={handleClick}>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img className='imgEditModal' src={imgpreview} alt="Preview" />
                        </div>
                        <div className='d-flex align-items-center justify-content-center m-3'>
                            <h5 className="text-center">Actualizar una imagen</h5>
                        </div>
                    </div>
                    <Form.Group className="mb-3 visually-hidden">
                        <Form.Control
                            type="file"
                            id="upload-button"
                            placeholder="Imagen"
                            name="files"
                            onChange={(e) => changeImage(e)}
                            ref={fileInput}
                        />
                    </Form.Group>

                    <Form onSubmit={handleFormSubmit}>
                        {/* Nombre */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Nombre</Form.Label>
                            <Form.Control type="text" placeholder="Nombre del cupón" name="name" defaultValue={detailCupon.name} />
                        </Form.Group>

                        {/* Tipo de cupón */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Tipo de cupón</Form.Label>
                            <Form.Select name="type" defaultValue={detailCupon.type} onChange={(e) => { setCuponType(e.target.value); setDiscountType(discount_type);  }}>
                                <option value="ORDER">Toda la compra</option>
                                <option value="PRODUCT">Producto en específico</option>
                            </Form.Select>
                        </Form.Group>


                        {/* Tipo de descuento */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Tipo de descuento</Form.Label>
                            <Form.Select name="discount_type" defaultValue={detailCupon.discount_type} onChange={(e) => { setDiscountType(e.target.value); }}>
                                <option value="PERCENTAGE">Porcentaje</option>
                                <option value="FIXED">Monto</option>
                                <RenderIf isTrue={cuponType === "PRODUCT"}>
                                    <option value="FREE_PRODUCT">Producto gratis</option>
                                </RenderIf>
                            </Form.Select>
                        </Form.Group>

                        <RenderIf isTrue={cuponType === 'ORDER' || detailCupon.type === 'ORDER'}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Valor mínimo de la orden</Form.Label>
                                <Form.Control type="text" placeholder="0.00" name="min_order_value" defaultValue={detailCupon.minAmount !== "No definido" ? detailCupon.minAmount : 0} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Máximo descuento</Form.Label>
                                <Form.Control type="text" placeholder="0.00" name="max_discount" defaultValue={detailCupon.maxDiscount !== "No definido" ? detailCupon.maxDiscount : detailCupon.discount_value} />
                            </Form.Group>
                        </RenderIf>

                        {/* Valor */}
                        <RenderIf isTrue={discount_type !== "FREE_PRODUCT"}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Valor</Form.Label>
                                <Form.Control type="text" placeholder="0.00" name="discount_value" defaultValue={detailCupon.discount_value} />
                            </Form.Group>
                        </RenderIf>

                        {/* Opciones adicionales */}
                        <Form.Check
                            type="checkbox"
                            label="¿Fecha límite?"
                            defaultChecked={detailCupon.expirationDate !== "No definido" ? true : false}
                            onChange={(e) => setShowDateLimit(e.target.checked)}
                        />
                        <RenderIf isTrue={showDateLimit || detailCupon.expirationDate !== "No definido"}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Fecha de expiración</Form.Label>
                                <Form.Control type="date" name="expiration_date" defaultValue={detailCupon.expirationDate} />
                            </Form.Group>
                        </RenderIf>

                        {/* Descripcion */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Descripcion</Form.Label>
                            <Form.Control type="texarea" placeholder="Descripcion del cupon" name="description" defaultValue={detailCupon.description} />
                        </Form.Group>

                        {/* Botón de guardar */}
                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalUpdateCupon;
