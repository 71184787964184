import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Components
import Select from 'react-select'
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import BuyModal from './Modals/BuyModal';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { BuysFetchAPI } from '../../api/Buys';
import { SuppliersFetchAPI } from '../../api/Suppliers';
import { GroupsFechtAPI } from '../../api/Groups'
import { WarehousesFetchAPI } from '../../api/Warehouses';
//Alerts 
import ErrorAlert from '../Alerts/ErrorAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
//Icons
import { FaTrashAlt } from 'react-icons/fa'
import LoadingAlert from '../Alerts/LoadingAlert';

const BuyForm = () => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [businessSupliers, setBusinessSupliers] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    //States products
    const [products, setProducts] = useState([]);
    const [productSelected, setProduct] = useState('null');
    const [productsList, setProductsList] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
    const [invoiceTax, setInvoiceTax] = useState(0);
    const [invoiceDiscount, setInvoiceDiscount] = useState(0);
    //Modal states 
    const [showModal, setShowModal] = useState(false);

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
        } catch (err) {
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
        }
    }
    //Function to get the warehouses info by branchoffice
    const getWarehouses = async (branchoffice_id) => {
        try {
            console.log(branchoffice_id)
            const response = await WarehousesFetchAPI.getWarehousesByBranchoffice(branchoffice_id, token);
            setWarehouses(response.data.warehouses); 
        } catch (err) {
            setWarehouses([]);
        }
    }

    //Function to get business suppliers 
    const getBusinessSuppliers = async (business_id) => {
        try {
            const response = await SuppliersFetchAPI.getBusinessSupplier(business_id, token);
            setBusinessSupliers(response.data.suppliers)
        } catch (err) {
            setBusinessSupliers([]);
        }
    }
    //Function to populate the supplier list
    const supplierList = () => {
        const supplierList = businessSupliers.map((supplier) => (
            <>
                <option value={supplier.id}>{supplier.name + ' Ruc:  ' + supplier.ruc}</option>
            </>
        ));
        return supplierList
    }
    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code} data-branchofficeid={branch.branchoffice_id}>{branch.branchoffice_name} </option>
            </>
        ));
        return listBracnh
    }
      //Function to populate the branch warehouses 
      const warehousesList = () => {
        const listWarehouses = warehouses.map((warehouse) => (
            <>
                <option value={warehouse.id}>{warehouse.name} </option>
            </>
        ));
        return listWarehouses
    }
    //Function to load products
    const loadProducts = async () => {
        try {
            //We search the groups of the user (we send 0 to load the user products available)
            const response = await GroupsFechtAPI.getGroupsByBranchoffice(selectedBranch.id, token);
            setProducts(response.data.data);
        } catch (err) {
            setProducts([])
        }
    };
    //Get Products 
    const optionsProducts = products.map((product) => ({
        label: product.name,
        value: product.group_id,
    }));
    //Function to select a product
    const handleChangeProducts = (option) => {
        setProduct(option);
    };

    //Function to add a product 
    const addProduct = async (e) => {
        e.preventDefault();
        //we validate that the user select a product
        if (productSelected === 'null') {
            ErrorAlert('Seleccione un producto', 'Error', 'warning')
        } else {
            //We get the product selected by the user 
            const product_selected = products.find(e => e.group_id === productSelected.value);
            //We add the products 
            setProductsList([...productsList, {
                "name": product_selected.name,
                "code": '',
                "cant": 1,
                "price": 0,
                "tax": '0%',
                "total_product_itbms": 0,
                "total_discount": 0,
                'unit_discount': 0,
                "subtotal_product": 0,
                "total_product": 0,
                "group_id": productSelected.value,
            }]);
            //We calculate the invoice totals 
            calculateInvoiceTotals(productsList)
        }
    }

    //Function to edit the table product 
    const handleEditTableProduct = (indexCell, cellName, cellValue) => {
        //We create a new array with the edit product
        const editProducts = productsList.map((product, index) => {
            let newProductCant = product.cant;
            let newProductDiscount = product.total_discount;
            let newProductPrice = product.price;
            let newProductCode = product.code;
            let newUnitDiscount = product.unit_discount;
            //We get the itbms and we calculate the total itbms
            let itmb_value = product.tax.split('%')[0] / 100;

            let total_product_itbms = 0;
            let subtotal_product = 0
            let total_product = 0;
            let total_discount = 0;
            //We check if we want to update the amount of the product
            if (cellName === 'cant') {
                newProductCant = cellValue;
                //We validate that the cant comes like an string
                if (newProductCant === '' || newProductCant < 1) {
                    newProductCant = 1
                }
            }

            //We check if we want to update the tax of the product
            if (cellName === "tax") {
                itmb_value = cellValue.split('%')[0] / 100;
            }

            //We check if we want to update the total discount of the product
            if (cellName === "total_discount") {
                newProductDiscount = (((cellValue / 100) * newProductPrice) * newProductCant);
                newUnitDiscount = ((cellValue / 100) * newProductPrice);
                //We validate that the discount comes like an string
                if (newProductDiscount === '' || newProductDiscount < 0) {
                    newProductDiscount = 0;
                    newUnitDiscount = 0;
                }
            }

            //We check if we want to update the price of the product
            if (cellName === "price") {
                newProductPrice = cellValue
                //We validate that the price comes like an string
                if (cellValue === '' || cellValue < 0) {
                    newProductPrice = 0
                }
            }


            //We check if we want to update the price of the product
            if (cellName === "code") {
                newProductCode = cellValue
                //We validate that the price comes like an string
                if (newProductCode === '') {
                    newProductCode = ''
                }
            }

            //We found the product to edit we return it 
            if (index === indexCell) {
                //We calculate the total discount of the item 
                total_discount = newUnitDiscount * newProductCant;
                //We calculate the total product itbms 
                total_product_itbms = newProductCant * (itmb_value * (newProductPrice - newUnitDiscount))
                //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
                subtotal_product = (newProductCant * (newProductPrice - newUnitDiscount));
                //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
                total_product = (newProductCant * (newProductPrice - newUnitDiscount)) + total_product_itbms;
                return {
                    ...product,
                    [cellName]: cellValue,
                    "subtotal_product": subtotal_product,
                    "total_product": total_product,
                    "total_product_itbms": total_product_itbms,
                    "total_discount": total_discount,
                    'unit_discount': newUnitDiscount
                };
            }
            //We return the product 
            return product;
        });
        //We set the products edited
        setProductsList(editProducts);
        //We calculate the invoice 
        calculateInvoiceTotals(editProducts);
        //We reload the component
        setEventHandler(1)
    };

    //Function to calculate the invoice totals
    const calculateInvoiceTotals = (products) => {

        //For every new calculate we set the totals
        let invoiceTotalDiscount = 0
        let invoiceSubtotal = 0
        let invoiceTotalItbms = 0
        let invoiceTotal = 0
        //if we dont we products we put the totals in 0 for default
        if (products.length === 0) {
            //We calculate the totals of the invoice
            setInvoiceTotal(0);
            setInvoiceDiscount(0);
            setInvoiceSubtotal(0);
            setInvoiceTax(0);
        } else {
            //For every product we calculate
            for (let i = 0; i < products.length; i++) {
                let product_info = products[i]
                //Variables
                let total_product_price = 0;
                let total_product_itbms = 0;
                let product_total_discount = 0;
                let product_tax = product_info.tax.split('%')[0];
                let product_tax_value = product_tax / 100;
                let product_unit_discount = product_info.unit_discount;
                //We set the total discount of the product
                product_total_discount = (product_unit_discount * product_info.cant);
                //We set the total of the product
                total_product_price = (product_info.cant * (product_info.price - product_unit_discount));
                //We set the itbms of the product
                total_product_itbms = (product_info.cant * ((product_info.price - product_unit_discount) * product_tax_value));

                //We calculate the totals of the invoice
                invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
                invoiceSubtotal = invoiceSubtotal + total_product_price;
                invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
                invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
            }
            invoiceTotal = Math.round(invoiceTotal * 100) / 100;
            //We set the totals
            setInvoiceTotal(invoiceTotal);
            setInvoiceSubtotal(invoiceSubtotal);
            setInvoiceDiscount(invoiceTotalDiscount);
            setInvoiceTax(invoiceTotalItbms);

        }
    }

    //Function to render the table preview invoice table 
    const tableProduct = () => {
        let productData = productsList;
        let rows = productData.map((product, index) => (
            <tr key={index}>
                <td>{product.name}</td>
                <td>
                    <input type="text"
                        defaultValue={product.code}
                        className='form-control'
                        onChange={(e) => handleEditTableProduct(index, "code", e.target.value)}
                    />
                </td>
                <td>
                    <input
                        type='number'
                        defaultValue={product.cant}
                        className='form-control'
                        onChange={(e) => handleEditTableProduct(index, "cant", e.target.value)}

                    /></td>
                <td>
                    <input type="number"
                        step='0.00000001'
                        defaultValue={product.price}
                        className='form-control'
                        onChange={(e) => handleEditTableProduct(index, "price", e.target.value)}
                    />
                </td>
                <td>
                    <select className='form-select' aria-label="Select payment type"
                        onChange={(e) => handleEditTableProduct(index, "tax", e.target.value)}
                        value={product.tax}>
                        <option value="7%">7%</option>
                        <option value="0%">0%</option>
                        <option value="10%">10%</option>
                        <option value="15%">15%</option>
                    </select>
                </td>
                <td>
                    <input
                        type='number'
                        step='0.00000001'
                        defaultValue={product.total_discount}
                        onChange={(e) => handleEditTableProduct(index, "total_discount", e.target.value)}
                        className='form-control'
                    />
                </td>
                <td>
                    ${product.unit_discount.toFixed(2)}
                </td>
                <td>{product.total_product.toFixed(2)}</td>
                <td>
                    <button onClick={(e) => deleteProduct(index, e)} className="btn btn-outline-danger ">
                        <i className="uil uil-trash-alt"></i>
                    </button>
                </td>
            </tr>
        ))
        return (
            <>
                {rows <= 0
                    ? <>
                        <div className="alert alert-danger w-100 mt-2 fw-bold " role="alert">
                            Actualmente no se ha agregado ningún producto.
                        </div>
                    </>
                    : rows
                }
            </>
        )
    }

    //Function to delete a product of the table preview 
    const deleteProduct = async (product_index, e) => {
        e.preventDefault();
        //We delete the product 
        productsList.splice(product_index, 1);
        setProductsList([...productsList]);
        //We calculate the invoice totals 
        calculateInvoiceTotals([...productsList])
    }

    //Function to register a new buy 
    const newBuy = async () => {
        //We close the modal
        setShowModal(false);
        //Loading alert 
        LoadingAlert('Insertando inventario', 'Espere un momento');
        //We get the info of the form 
        const form = new FormData(document.querySelector('form'));
        form.append('branchoffice_id', selectedBranch.id);
        form.append('business_id', user.business.id);
        form.append('subtotal', invoiceSubtotal);
        form.append('tax', invoiceTax);
        form.append('discount', invoiceDiscount);

        let buy = Object.fromEntries(form.entries());
        //We add the products to the json
        buy.products = productsList;
        //We check if the total inserted 
        if (buy.total !== '' && buy.total !== null) {
            try {
                //We register the buy 
                const response = await BuysFetchAPI.newBuy(buy, token);
                //Success alert 
                SuccessAlert(response.data.message);
            } catch (err) {
                //Error alert 
                ErrorAlert(err.response.data.error, 'Error!', 'warning');
            }
        } else {
            //Error alert 
            ErrorAlert('El Total ingresado de la factura no puede estar vacio', 'Error!', 'warning');
        }
    }


    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We set the business suppliers 
        getBusinessSuppliers(user.business.id)
    }, []);


    //Hook to recharge data on the page 
    useEffect(() => {
        setProductsList([...productsList]);
        setWarehouses([...warehouses]);
        setEventHandler(0)
    }, [eventHandler]);

    return (
        <>
            <div>

                <div className='d-flex justify-content-between'>

                    <h4>Nueva compra</h4>

                    <small><b className='text-danger'>*</b>
                        <span className='text-secondary ms-1'>
                            Campos Obligatorios
                        </span>

                    </small>
                </div>

                <form onSubmit={newBuy} className="row  bg-white p-0 p-lg-2  mt-1 border-sp border ">
                    <div className='col-12 mb-2 mt-1'>
                        <span className='h5 text-primary-emphasis fw-bold '>
                            Detalles de la Compra
                        </span>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4  col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Sucursal <b className='text-danger'> * </b></label>
                            <select onChange={e => { getBranchoffice(business_code, e.target.value);  getWarehouses(document.querySelector(`option[value=\"${e.target.value}\"]`).dataset.branchofficeid); setEventHandler(1); loadProducts() }} className='form-select' name="branch" id="branch">
                                <option value={0}>Seleccione...</option>
                                {branchList()}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4  col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Bodega <b className='text-danger'> * </b></label>
                            <select  className='form-select' name="warehouse_id" id="warehouse_id">
                                <option value={0}>Seleccione...</option>
                                {warehousesList()}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4 col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Fecha de compra<b className='text-danger'> * </b></label>
                            <input type="date" name="invoiceDate" className="form-control" />
                        </div>
                    </div>


                    <div className="col-12 col-sm-4 col-md-4 col-lg-4   mt-1">
                        <label htmlFor="input_from">Número de factura <b className='text-danger'> * </b></label>
                        <input type="text" name="invoice" className="form-control" placeholder='Factura de compra xxxxx-xxxxxx' />
                    </div>



                    <div className='col-12  mb-2 mt-lg-3 mt-1'>
                        <div className=' opacity-25'>
                            <hr />
                        </div>
                        <span className='h5 text-primary-emphasis fw-bold '>
                            Detalles del Pedido
                        </span>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4  col-lg-4   mt-1">
                        <label htmlFor="input_from">Total de la compra <b className='text-danger'> * </b></label>
                        <input type="text" name="total" className="form-control" placeholder='$ 0.00' />
                    </div>
                    <div className="col-6 col-sm-4 col-md-4 col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Proveedor (opcional)</label>
                            <select className='form-select' name="supplier_id" id="supplier_id">
                                <option value={0}>Seleccione...</option>
                                {supplierList()}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4 col-lg-4   mt-1">
                        <div className="form-group">
                            <label htmlFor="input_from">Productos comprados</label>
                            {/**Product select  */}
                            <div className=''>
                                <Select
                                    className='w-100'
                                    value={productSelected}
                                    options={optionsProducts}
                                    onChange={handleChangeProducts}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-12  mt-lg-12 mt-3  ">
                        <div className='d-flex justify-content-end'>
                            <button type="button" className='btn btn-primary' onClick={addProduct} >Agregar Producto </button>
                        </div>
                    </div>


                    <div className='col-12 mb-2 mt-1'>
                        <div className=' opacity-25'>
                            <hr />
                        </div>
                        <span className='h5 text-primary-emphasis fw-bold '>
                            Detalles de Productos
                        </span>
                    </div>

                    {/**Product table  */}
                    <Table responsive>
                        <thead className='table-dark'>
                            <tr>
                                <th>Producto</th>
                                <th>Código (upc/ean)</th>
                                <th>Cantidad </th>
                                <th>Precio unitario</th>
                                <th>Impuesto(itbms)</th>
                                <th>Descuento %</th>
                                <th>Descuento unitario</th>
                                <th>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableProduct()}
                        </tbody>
                    </Table>
                    <div className='d-flex justify-content-end '>
                        <div className='flex-column  border rounded-4 p-3'>

                            <div className="py-1 d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0 ">Descuento:</h6>
                                </div>
                                <span className="text-muted ">${invoiceDiscount.toFixed(2)}</span>
                            </div>
                            <div className="py-1 d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0 ">Subtotal:</h6>

                                </div>
                                <span className="text-muted ">${invoiceSubtotal.toFixed(2)}</span>
                            </div>

                            <div className="py-1 d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Total impuestos:</h6>

                                </div>
                                <span className="text-muted text-end">${invoiceTax.toFixed(2)}</span>
                            </div>

                            <div className="py-1 d-flex justify-content-between lh-sm ">
                                <div>
                                    <h6 className="my-0">Total:</h6>

                                </div>
                                <span className="text-muted ">${invoiceTotal.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </form>

                {/* Modal de confirmación */}
                <BuyModal
                    showModal={showModal}
                    selectedBranch={selectedBranch}
                    productsList={productsList}
                    invoiceDiscount={invoiceDiscount}
                    invoiceSubtotal={invoiceSubtotal}
                    invoiceTax={invoiceTax}
                    invoiceTotal={invoiceTotal}
                    setShowModal={setShowModal}
                    newBuy={newBuy}
                    setEventHandler={setEventHandler}
                    eventHandler={eventHandler}
                    modalTitle='Confirmación de Compra'
                    modalType='buy_confirmation'
                    buttonModal={true}
                    voidedBuyDetails={{ invoice: 0 }}
                />
            </div>
        </>


    );
}

export default BuyForm;
