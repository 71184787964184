import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': 'application/json'}
})

export class TaxesFetchAPI { 
    //Function to get the branchoffice tables 
    static async getTaxes(token) {
        const res = await server(`/taxes`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res;
    }

    static async asingTaxToGroup(data, token) {
        const res = await server(`/tax_per_group`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }

    static async asingTaxToCombo(data, token) {
        const res = await server(`/tax_per_combo`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }


    
    static async deleteTaxOfCombo(data, token) {
        const res = await server(`/tax_per_combo`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }


    

    static async asingTaxToPromotion(data, token) {
        const res = await server(`/tax_per_promotion`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }

    static async asingTaxToItemOfSegment(data, token) {
        const res = await server(`/tax_per_itemsegment`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }

    static async deleteTaxByGroupId(data, token) {
        const res = await server(`/tax_per_group`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }

    static async deleteTaxByPromotionId(data, token) {
        const res = await server(`/tax_per_promotion`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }

    static async deleteTaxOfItemAndSegment(data, token) {
        const res = await server(`/tax_per_itemsegment`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res;
    }

}