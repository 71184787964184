import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//API 
import { CouponPosFetchAPI } from '../../../api/Coupon';
//Alerts 
import AlertError from '../../Alerts/ErrorAlert';

//Modal asing branchoffice cupon
const ModalAsingBranchOfficeCupon = ({ showModal, setShowModal, selectedCupon, setEventHandler, user }) => {

    //Function to add a branchoffice to the cupon
    const addBranchToCupon = async (e) => {
        e.preventDefault();
        const branch = e.target.branch.value;
        try {
            let res = await CouponPosFetchAPI.addBranchOfficeToCupon(selectedCupon, branch, localStorage.getItem('x-access-token'));

            if (res.status === 200) {
                AlertError('Combo asignado correctamente a la sucursal', 'Exito', 'success')
                setEventHandler(1)
            }
        } catch (err) {
            console.log(err)
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al asignar la sucursal al cupon', 'error', 'error')
        }


    }
    return (
        <div>
            <Modal
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asignar sucursal a cupón</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addBranchToCupon}>
                        <Form.Group controlId="branch">
                            <Form.Label>Sucursal</Form.Label>
                            <Form.Select>
                                <option value={null}>Seleccione sucursal...</option>
                                {user.branchoffices.map((branch) => (
                                    <option key={"branch" + branch.branchoffice_id} value={branch.branchoffice_id}>{branch.branchoffice_name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
                            <Button variant="success" type='submit'>Guardar</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>


            </Modal>
        </div>
    );
}

export default ModalAsingBranchOfficeCupon;
