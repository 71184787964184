//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select'
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { MachinesFetchAPI } from '../../api/Machines';
import { PaymentMethodFetchAPI } from '../../api/PaymentMethod';
import { CategoriesDashFetchAPI } from '../../api/CategoriesDash';
import { WarehouseFetchAPI } from '../../api/Warehouse';

//Internal components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import InAndExit from './InAndExit';
import Transfer from './Transfer';
import Reception from './Reception';
import Warehouse from './Warehouse';
import Report from './Report';
//Modals 
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const Inventory = () => {
    //Params 
    let { business_code } = useParams();
    //LocalStorage
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    const [warehouses, setWarehouses] = useState([])

    //Machines 
    const [machinesList, setMachinesList] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(0);
    //Modal states 
    const [showModalDetails, setShowModalDetails] = useState(false);

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get the warehouse of branch office
    const getWarehouseByBrachoffice = async (branchoffice_id) => {
        try {
            const response = await WarehouseFetchAPI.getwarehousebybracnhoffice(branchoffice_id, 0, token)
            console.log(response);
            setWarehouses(response.data.warehouses)
            console.log(response.data.warehouses);
            setEventHandler(1)

        } catch (err) {
            console.log(err);
        }
    }





    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        setEventHandler(0);
    }, []);

    //Hook to get the machines of the branch office
    useEffect(() => {
        if (selectedBranch.code !== 0) {
            getWarehouseByBrachoffice(selectedBranch.id)
        }
        setEventHandler(0)
    }, [eventHandler])

    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <NavBar titlePage="Inventory" />
                </div>
                <div className='other-column '>
                </div>
                {/** Main container */}
                <div className="m-2">
                    <section className='banner-reports d-flex d-lg-flex justify-content-lg-between align-items-center
                     mb-4 banner-sp rounded-4 py-2 px-3 '>
                        <div>
                            <div className='d-flex justify-content-lg-between align-items-center'>
                                <h2 className='banner-reports-title mb-0 pb-0'>
                                    Inventario
                                </h2>
                            </div>
                            <p className='m-0 p-0 banner-reports-description'>Gestion de inventario.</p>
                        </div>
                    </section>



                    <Tab.Container defaultActiveKey="reports">
                        <Row>
                            <Col sm={12} >
                                <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="reports" title="Reportes" ><i className="uil uil-file-graph"></i> Reportes</Nav.Link>
                                    </li>
                                    <li className="nav-sp-item"  >
                                        <Nav.Link eventKey="in" ><i className="uil uil-sign-in-alt"></i> Entrada</Nav.Link>
                                    </li>
                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="out"><i className="uil uil-sign-out-alt"></i> Salida</Nav.Link>
                                    </li>
                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="send" ><i className="uil uil-exchange"></i> Transferencia</Nav.Link>
                                    </li>
                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="receive" ><i className="uil uil-envelope-download-alt"></i> Recibir</Nav.Link>
                                    </li>
                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="wherehouse" ><i className="uil uil-database"></i> Bodegas</Nav.Link>
                                    </li>
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="reports" title="Reportes inventario">
                                        <div className=' m-0'>
                                            <Report selectedBranch={selectedBranch}></Report>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="in">
                                        <div className='m-0'>
                                            <InAndExit Type={"In"}></InAndExit>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="out" >
                                        <div className='m-0'>
                                            <InAndExit Type={"Exit"}></InAndExit>
                                        </div>
                                    </Tab.Pane>


                                    <Tab.Pane eventKey="send" title="envios">
                                        <div className='m-0'>
                                            <InAndExit Type={"Transfer"}></InAndExit>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="receive" title="Recibir">
                                        <div className='m-0'>
                                            <Reception></Reception>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="wherehouse" title="wherehouse">
                                        <div className='m-0'>
                                            <Warehouse selectedBranch={selectedBranch} warehouses={warehouses} setWarehouses={setWarehouses} setEventHandler={setEventHandler}></Warehouse>
                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>


                    <section className=''>
                        <div className="row justify-content-md-center mt-5">
                            {/* <div className="col-12 col-md-12 col-lg-4 m-1 w-100">
                                <div className="card-analitics-sp shadow-sm">
                                    <div className="card-analitics-sp-header d-flex justify-content-between">
                                        <span>Ingreso de inventario</span>
                                    </div>
                                    <span className="card-value-total fw-bold text-color-sp"><i className="uil uil-sign-in-alt"></i> Ingreso</span>
                                </div>
                            </div>
                           <div className="col-4 col-md-4 col-lg-4 m-1">
                                <div className="card-analitics-sp shadow-sm">
                                    <div className="card-analitics-sp-header d-flex justify-content-between">
                                        <span>Envio de inventario</span>
                                    </div>
                                    <span className="card-value-total fw-bold text-color-sp"><i className="uil uil-envelope-upload"></i> Envio</span>
                                </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4 m-1">
                                <div className="card-analitics-sp shadow-sm">
                                    <div className="card-analitics-sp-header d-flex justify-content-between">
                                        <span>Recepcion de envios inventario</span>
                                    </div>
                                    <span className="card-value-total fw-bold text-color-sp"><i className="uil uil-file-check-alt"></i> Recepcion</span>
                                </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4 m-1">
                                <div className="card-analitics-sp shadow-sm">
                                    <div className="card-analitics-sp-header d-flex justify-content-between">
                                        <span>Salidas de inventario</span>
                                    </div>
                                    <span className="card-value-total fw-bold text-color-sp"><i className="uil uil-sign-out-alt"></i> Salida</span>
                                </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4 m-1">

                                <div className="card-analitics-sp shadow-sm">
                                    <div className="card-analitics-sp-header d-flex justify-content-between">
                                        <span>Reportes de moviemientos de inventario</span>
                                    </div>
                                    <span className="card-value-total fw-bold text-color-sp"><i className="uil uil-file-graph"></i> Reportes</span>
                                </div>
                            </div>*/}
                        </div>

                    </section>
                </div>
            </div>
        </>
    );
}

export default Inventory;
