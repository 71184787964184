//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select'
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
//API
import { WarehouseFetchAPI } from '../../../api/Warehouse';

//Modals 
import { Modal } from 'react-bootstrap';
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
const ModalNewUpdateWarehouse = ({ showModalNewWarehouse, setShowModalNewWarehouse, selectedBranch, warehouseSelect,  getWarehouseByBrachoffice}) => {
    const token = localStorage.getItem('x-access-token');
    
    const [warehouseDefauld, setWarehouseDefauld] = useState(false)

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        form.append("branchoffice_id", selectedBranch.id)
        let data = Object.fromEntries(form.entries());
        console.log(selectedBranch);

        if(warehouseSelect.id){
            console.log(data);
            updateWarehouse(data)
        }else{
            createWarehouse(data)
        }
       
    }

    //Function create warehouse
    const createWarehouse = async(data) =>{
        try {
            //We update the data 
            await WarehouseFetchAPI.createWarehouse(data, token);
          
            ErrorAlert('', 'Producto creado con exito', 'success')
            setShowModalNewWarehouse(false)
            getWarehouseByBrachoffice(selectedBranch.id)
        
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de creacion', 'error')
            console.log(err)
        }
    }

    //Function update to warehouse
    const updateWarehouse = async(data) =>{


        if(data.Location == ""){
            data.Location = warehouseSelect.location
        }
        if(data.name == ""){
            data.name = warehouseSelect.name
        }

        try{
            const response = await WarehouseFetchAPI.updateWarehouse(warehouseSelect.id, data, token)
            console.log(response);
            setShowModalNewWarehouse(false)
            ErrorAlert('', 'Producto actulizado con exito', 'success')
            getWarehouseByBrachoffice(selectedBranch.id)

        }catch(err){
            ErrorAlert(err.response.data.error, 'Error de actualizacion', 'error')
            console.log(err);
        }
    }



    return (
        <>
            <Modal
                show={showModalNewWarehouse}
                onHide={() => setShowModalNewWarehouse(false)}
                size='xl'
                centered
            >
                <Modal.Header>
                    <h1>  {warehouseSelect.id?<><i className="uil uil-edit"></i> Editar bodega</>:<><i className="uil uil-plus-circle"></i> Nueva bodega</>}</h1>

                </Modal.Header>
                <Modal.Body>
                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre de la bodega</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder= {warehouseSelect.id?warehouseSelect.name:"Ejemplo: bodega 1"} 
                                name="name"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Localizacion de la bodega</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder= {warehouseSelect.id?warehouseSelect.location:"Ejemplo: cocina"}
                                name="location"

                            />
                        </Form.Group>



                        <Form.Group className="mb-3" >
                            <Form.Label>Bodega por defecto</Form.Label>
                            <Form.Select aria-label="" name="is_default" onChange={e => setWarehouseDefauld(e.target.value)}>

                                <option value={0}>No</option>
                                <option value={1}>Si</option>

                            </Form.Select>
                        </Form.Group>

                        <div className="mt-3 d-flex justify-content-end">
                            
                            <Button size="sm" variant="success" type="submit">
                                 {warehouseSelect.id?<>Actualizar</>:<>Guardar</>}
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalNewWarehouse(false))}>Cerrar</Button>
                </Modal.Footer>

            </Modal>

        </>
    );
}

export default ModalNewUpdateWarehouse;
