import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//API 
import { CouponPosFetchAPI } from '../../../api/Coupon';
//utils
import DaysList from '../../utils/DaysList';
//Alerts 
import AlertError from '../../Alerts/ErrorAlert';

//Modal asing Day cupon
const ModalAsingDayCupon = ({ showModal, setShowModal, selectedCupon, setEventHandler }) => {
    //Function of days
    const days = DaysList();

    //Function to add a condition to the cupon
    const addCondtionToCupon = async (e) => {
        e.preventDefault();
        const day = e.target.day.value;
        try {
            let res = await CouponPosFetchAPI.addConditionCupon(selectedCupon, "DAY", day, localStorage.getItem('x-access-token'));

            if (res.status === 200) {
                AlertError('Dia asignado correctamente al cupon', 'Exito', 'success')
                setEventHandler(1)
            }
        } catch (err) {
            console.log(err)
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al asignar el dia al cupon', 'error', 'error')
        }

    }
    return (
        <div>
            <Modal
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asignar dia a cupón</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addCondtionToCupon}>
                        <Form.Group controlId="day">
                            <Form.Label>Dia</Form.Label>
                            <Form.Select>
                                <option value={null}>Seleccione dia...</option>
                                {days.map((day) => (
                                    <option key={"day" + day.value} value={day.value}>{day.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
                            <Button variant="success" type='submit'>Guardar</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>


            </Modal>
        </div>
    );
}

export default ModalAsingDayCupon;
