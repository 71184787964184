import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
//Utils 
import { RenderIf } from '../utils/RenderIf';
import { checkUserModules } from "../utils/CheckUserModules";
//Img
import logo_sp from '../img/logos/icon-black.png';
import user_profile from '../img/icons/usuario.png';
//CSS
import "./navbar.css"



const NavBar = ({ titlePage }) => {

    //Nav Bar 
    const [outLogShow, setOutLogShow] = useState(false);

    //Params 
    let { business_code } = useParams();
    let user = localStorage.getItem('user')

    const navigate = useNavigate();


    //We valilink if the user is logged in
    if (user === null || user === undefined || user === '') {
        user = {
            name: 'No registrado',
            img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }


    function signout() {
        //We delete the token 
        localStorage.setItem('x-access-token', "");
        //We check the role of the user and depending of it we redirect 
        if (user.role === 4) {
            navigate(`/cashier/login/${business_code}`)
        } else if (user.role === 5) {
            navigate(`/dispatcher/login/${business_code}`)
        } else if (user.role === 3 || user.role === 6 || user.role === 7 || user.role === 8) {
            navigate(`/login/${business_code}`)
        }
    }



    const toggleLogOut = () => {
        setOutLogShow(!outLogShow);

    };

    const menuMain = [
        {
            title: 'Notificaciones',
            icon: 'uil uil-bell',
            link: `/notifications/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('notification_module', user.modules) === -1 ? false : true
        },
        {
            title: 'Transacciones',
            icon: "uil uil-bill",
            link: `/transactions/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('pay_module', user.modules) === -1 ? false : true
        },
        {
            title: 'Productos',
            icon: 'uil uil-truck-loading ',
            link: `/products/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('product_module', user.modules) === -1 ? false : true

        },
        {
            title: 'Analíticas',
            icon: 'uil uil-file-graph',
            link: `/analitics/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('analitics_module', user.modules) === -1 ? false : true
        },
        {
            title: 'Reportes',
            icon: 'uil uil-file-landscape-alt',
            link: `/reports/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('report_module', user.modules) === -1 ? false : true

        },
        {
            title: 'Usuarios',
            icon: 'uil uil-user-square ',
            link: `/users/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('users_module', user.modules) === -1 ? false : true

        },

        {
            title: 'Compras',
            icon: 'uil-shopping-bag ',
            link: `/buys/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('buy_module', user.modules) === -1 ? false : true

        },
        {
            title: user.business.posIntegration !== null && user.business.posIntegration !== undefined ? user.business.posIntegration.name : 'Ninguna integracion',
            icon: 'uil-info-circle ',
            link: `/manager/integration/${business_code}`,
            integrationOption: true,
            permition: checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined ? true : false
        },
        {
            title: 'Precuentas',
            icon: 'uil uil-invoice ',
            link: `/temporal/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('preaccount_module', user.modules) === -1 ? false : true

        },
        {
            title: 'Webhook',
            icon: 'uil uil-sliders-v-alt',
            link: `/webhook/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('webhook_module', user.modules) === -1 ? false : true

        },
        {
            title: 'Vending',
            icon: 'uil uil-robot',
            link: `/vending/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('vending_module', user.modules) === -1 ? false : true

        },
        {
            title: 'Eventos',
            icon: 'uil uil-calendar-alt ',
            link: `/events/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('event_module', user.modules) === -1 ? false : true
        },
        {
            title: 'Máquinas',
            icon: 'uil uil-desktop',
            link: `/machines/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('machine_module', user.modules) === -1 ? false : true
        },
        {
            title: 'Inventario',
            icon: 'uil uil-database-alt',
            link: `/inventory/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('inventory_module', user.modules) === -1 ? false : true
        },
        {
            title: user.business.business_type.panamanianCode !== "9010" ? "Habitaciones"  : "Mesas",
            icon: user.business.business_type.panamanianCode !== "9010"  ? "uil uil-bed-double"  : 'uil uil-restaurant',
            link: `/tables/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('table_module', user.modules) === -1 ? false : true
        },
        {
            title:"Cupones",
            icon: 'uil uil-ticket',
            link: `/cupons/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('cupon_module', user.modules) === -1 ? false : true
        }
    ];


    const [isMenuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    // Función para cerrar el menú si se hace clic fuera del mismo
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false); // Cerrar el menú si el clic fue fuera del menú
        }
    };

    // useEffect para añadir el event listener de clic global
    useEffect(() => {
        // Añadir el event listener cuando el menú esté abierto
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup para quitar el event listener cuando se desmonte el componente
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <>
            <div ref={menuRef} className={`d-flex flex-column p-lg-1 text-black rounded-4 bg-white shadow-sm  ${isMenuOpen
                ? " sidebar-main-open"
                : " sidebar-main"}`}>

                <div className=" d-flex flex-lg-column flex-xl-column   justify-content-between align-items-center   rounded-pill p-lg-1 p-1">
                    <div className="d-flex justify-content-start align-items-center mb-0 mb-lg-2 mb-md-0 me-md-0 text-black text-decoration-none ">
                        <span className="navbar-logo logo">
                            <img className="navbar-logo-icon" src={logo_sp} alt="Logo Smart Pay" />
                            <span className="navbar-logo-text">Smart Pay</span>
                        </span>
                    </div>

                    <span className="area-profile d-flex align-items-center text-black  my-lg-2 m-0 p-lg-2 p-0 rounded-4 ">
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="conrainer-profile-img-sidebar rounded-4">
                                    <img className="profile-img-sidebar rounded-4"
                                        src={user_profile} alt="User" />
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-1 ">
                                <span className="fw-bold"> {user.name + " " + user.lastName} </span>
                                <small className="d-block text-muted opacity-50">Usuario</small>
                            </div>
                        </div>
                    </span>

                    <div className="icon-menu-sp  rounded-circle bg-primary-subtle  text-primary border-light border shadow-sm "
                        onClick={toggleMenu}>
                        <i className={`display-6 uil ${isMenuOpen
                            ? "uil-multiply "
                            : "uil-bars"}`}></i>

                    </div>
                </div>
                <div className={`w-100 text-center mt-1 border-top ${isMenuOpen ? " list-menu-main-sp-open" : " list-menu-main-sp"}`}>
                    <small className="fw-bold text-primary  text-subtitle-menu">Menú</small>
                </div>

                <div className={`mb-auto ${isMenuOpen ? " list-menu-main-sp-open slide-in-blurred-top" : " list-menu-main-sp slide-in-blurred-top"}`}>
                    <ul className="nav nav-pills flex-column ">
                        {menuMain.map((option, index) => (
                            <>
                                {option.integrationOption === false && option.permition === true ?
                                    <li key={index} className="nav-item" >
                                        <span>
                                            <NavLink to={option.link}
                                                className={option.title === titlePage ? 'nav-link fw-semibold' : '  nav-link fw-normal'}
                                            >
                                                <i className={option.icon}></i>
                                                <span className="mx-1">
                                                    {option.title}
                                                </span>
                                            </NavLink>
                                        </span>
                                    </li> :
                                    (option.integrationOption === true && user.business.pos_integration_id !== null) && option.permition === true ?

                                        <li key={index} >
                                            <span>
                                                <Link to={option.link}
                                                    className={option.title === titlePage ? 'nav-link fw-semibold' : '  nav-link fw-normal'}
                                                >
                                                    <i className={option.icon}></i>
                                                    <span className="mx-1">
                                                        {option.title}
                                                    </span>
                                                </Link>
                                            </span>
                                        </li>
                                        :
                                        ""}
                            </>
                        ))}
                    </ul>



                </div>
                <div className={`${isMenuOpen ? " list-menu-main-sp-open slide-in-blurred-top" : " list-menu-main-sp "}`}>
                    <div className="close-login rounded-3 py-2 fw-bold" onClick={() => signout()}>
                        <i className="uil uil-signout px-1 pt-3 ms-1"></i>
                        Cerrar Sesión
                    </div>
                </div>


            </div>
        </>
    );
}

export default NavBar;
