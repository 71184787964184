import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap';
//Api
import { OracleSimphonyFetchAPI } from '../../../api/OracleSimphony';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';
//Alerts
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';

export default function Simphony({ branchoffice, token, pos_integration_id, setSyncronized }) {
    const [showModal, setShowModal] = useState(false)
    const [simphonyMenus, setSimphonyMenus] = useState([]);
    const [simphonyMenuSelected, setSimphonyMenuSelected] = useState(null);
    const [simphonyMenu, setSimphonyMenu] = useState();
    const [simphonyTaxes, setSimphonyTaxes] = useState({ taxClasses: [], taxRates: [] });
    const [smartpayTaxes, setSmartpayTaxes] = useState([]);

    //Function to get the menus of simphony 
    const getSimphonyMenus = async () => {
        try {
            //We make the request just if we have a selected branchoffice 
            if (branchoffice !== 0) {
                const menus = await OracleSimphonyFetchAPI.getSimphonyMenus(branchoffice.id, token)
                setSimphonyMenus(menus.data.simphony_response.items);
            }
        } catch (err) {
            setSimphonyMenus([])
        }
    }

    //Function to get simphony menu by id 
    const getSimphonyMenuById = async (menuSelected) => {
        try {
            const menu = await OracleSimphonyFetchAPI.getSimphonyMenuById(menuSelected, branchoffice.id, token);

            console.log(menu.data.simphony_response)
            setSimphonyMenu(menu.data.simphony_response);

        } catch (err) {
            setSimphonyMenu(null)
        }
    }

    //Function to get simphony taxes
    const getSimphonyTaxes = async () => {
        try {
            if (branchoffice !== 0) {
                const taxes = await OracleSimphonyFetchAPI.getSimphonyTaxes(branchoffice.id, token);
                setSimphonyTaxes(taxes.data.simphony_response);
            }
        } catch (err) {
            setSimphonyTaxes({ taxClasses: [], taxRates: [] });
        }
    }

    //Function to get the smartpay taxes
    const getSmartpayTaxes = async () => {
        try {
            const taxes = await TaxesFetchAPI.getTaxes(token);
            setSmartpayTaxes(taxes.data.data);
        } catch (err) {
            setSmartpayTaxes([])
        }
    }


    //Function to insert menu on bulk
    const insertMenuOnBulk = async () => {
        try {
            let products = [];
            let categories = [];
            let segments = [];
            let items = [];
            let integrationTaxes = [];
            let combos = [];


            // Función para agregar productos y categorías
            function addProductAndCategories(productId, productName, productPrices, productImages, segmentRefs, productTaxes, category_id) {
                let productPrice = 0;
                let productTaxClass = []
                let productSmartpayTaxes = [];

                //We checkc if the product has more than one price 
                if (productPrices.length > 0) {
                    let index = productPrices.findIndex(price => price.name === 'Default' || price.name === 'SM' || price.priceSequence === 1);
                    //If we dont find any product we just add the first price 
                    if (index === undefined || index === -1) {
                        productPrice = productPrices[0].price;
                    } else {
                        productPrice = productPrices[index].price;
                    }
                } else {
                    productPrice = 0
                }

                //We add the taxes 
                productTaxClass = simphonyTaxes.taxClasses.find(element => element.taxClassId === productTaxes[0].taxClassRef);

                //We check if extist any tax class
                if (productTaxClass !== undefined && productTaxClass !== null) {
                    //Fro the product tax class we ge the active tax rate refs 
                    productTaxClass.activeTaxRateRefs.map(tax => {
                        let taxRate = integrationTaxes.find(element => element.taxRateId == tax)
                        productSmartpayTaxes.push({ tax_id: taxRate ? taxRate.smartpayTaxId : 4 })
                    })
                }


                // Almacenar el producto en el array de productos con su ID
                products.push({ id: productId, productImages: productImages === undefined ? [] : productImages, preparation: 0, external_reference: productId, segmentsRefs: segmentRefs === undefined ? [] : segmentRefs, description: productName, name: productName, price: productPrice, pos_integration_id: pos_integration_id.pos_integration_id, taxes: productSmartpayTaxes });

                //We search the combo on the categories
                let indexCategory = categories.findIndex(cat => cat.id == category_id);

                // Check of coincidences of the categories
                if (indexCategory !== -1) {
                    //If the category is on the array we get if the reference of the product is on the category
                    let referenceCoincidence = categories[indexCategory].productsReference
                        .some(pr => pr === productId);
                    //If the product is not on the category we insert it 
                    if (!referenceCoincidence) {
                        categories[indexCategory].productsReference
                            .push(productId);
                    }
                }
            }

            //Function to add combos and categories 
            function addComboAndCategories(comboId, category_id) {
                //We search the combo on the categories
                let index = categories.findIndex(cat => cat.id == category_id);

                // Check of coincidences of the categories
                if (index !== -1) {
                    //If the category is on the array we get if the reference of the product is on the category
                    let referenceCoincidence = categories[index].combosReference.some(pr => pr === comboId);
                    //If the product is not on the category we insert it 
                    if (!referenceCoincidence) {
                        categories[index].combosReference.push(comboId);
                    }
                }
            }

            //We map the taxes of simphony to the taxes of smartpay
            integrationTaxes = simphonyTaxes.taxRates.map(tax => {
                const taxCoincidence = smartpayTaxes.find(element => element.taxtValue === tax.percentage);
                return { taxRateId: tax.taxRateId, smartpayTaxId: taxCoincidence ? taxCoincidence.id : 4, taxName: tax.name, taxValue: tax.percentage }
            });

            //Family groups 
            simphonyMenu.familyGroups.forEach(element => {
                let name = element.name["en-US"];
                let id = element.familyGroupItemID;
                categories.push({ name: name, description: name, id: id, category_type: 2, categoryImages: [], external_reference: id, productsReference: [], combosReference: [] });
            });
            //Menu items
            simphonyMenu.menuItems.forEach(element => {
                // We check if the menuItem has any definition 
                if (element.definitions) {
                    // We get the product name 
                    const productName = element.name["en-US"];
                    const productId = element.menuItemId;
                    let familyGroupId = element.familyGroupRef;
                    //We get the prices of the product 
                    const productPrices = Array.from(
                        new Set(element.definitions
                            .filter(definition => definition.prices)
                            .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name ? price.name : null, priceSequence: price.priceSequence }))))
                    );

                    //We get the segmentRefs of the product 
                    const segmentsRefs = Array.from(
                        new Set(element.definitions
                            .filter(definition => definition.condimentGroupRules)
                            .flatMap(definition => definition.condimentGroupRules.map(segment => (segment.condimentGroupRef))))
                    )

                    //We get the product img of the product 
                    const productImages = Array.from(
                        new Set(element.definitions
                            .filter(definition => definition.images)
                            .flatMap(definition => definition.images.map(image => ({
                                url: image.url,
                                altText: image.altText,
                                description: image.description,
                                size: image.size
                            }))))
                    )
                    //We match the taxes of the product 
                    const productTaxes = Array.from(
                        new Set(element.definitions.map(definition => ({ taxClassRef: definition.taxClassRef }))))

                    //We call the function to add the products and categories 
                    addProductAndCategories(productId, productName, productPrices, productImages, segmentsRefs, productTaxes, familyGroupId);
                }
            });

            //Individual items 
            simphonyMenu.condimentItems.forEach(element => {
                const itemName = element.name['en-US'];
                const itemId = element.condimentId;
                const itemPrices = Array.from(
                    new Set(element.definitions
                        .filter(definition => definition.prices)
                        .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name })))
                    )
                )
                let itemPrice = 0;
                //We checkc if the item has more than one price 
                if (itemPrices.length > 1) {
                    let index = itemPrices.find(price => price.name === 'Default');

                    //If we dont find any product we just add the first price 
                    if (index === undefined) {
                        itemPrice = itemPrices[0].price;
                    } else {
                        itemPrice = index.price
                    }
                } else {
                    itemPrice = 0
                }

                //We get the images of the items 
                const itemImages = Array.from(
                    new Set(element.definitions
                        .filter(definition => definition.images)
                        .flatMap(definition => definition.images.map(image => ({
                            url: image.url,
                            altText: image.altText,
                            description: image.description,
                            size: image.size
                        }))))
                )

                //We get put the information 
                items.push({ name: itemName, id: itemId, itemImages: itemImages === undefined ? [] : itemImages, price: itemPrice, item_type: "infinite", item_preparation: 0, description: itemName, external_reference: itemId, pos_integration_id: pos_integration_id.pos_integration_id });
            })

            //Segments 
            simphonyMenu.condimentGroups.forEach(element => {
                const segmentName = element.name["en-US"];
                const segmentId = element.condimentGroupId;
                const itemsRefs = element.condimentItemRefs;
                segments.push({ name: segmentName, description: segmentName, id: segmentId, itemsRefs: itemsRefs, external_reference: segmentId, pos_integration_id: pos_integration_id.pos_integration_id })
            });

            //Combo meals 
            simphonyMenu.comboMeals.forEach((element) => {
                // Verificamos si el combo tiene un menuItemRef
                if (element.menuItemRef) {
                    let comboRef = simphonyMenu.menuItems.find(product => product.menuItemId === element.menuItemRef);
                    let comboId = element.comboMealId;
                    let comboName = element.name;
                    let productTaxClass = null;
                    let productSmartpayTaxes = [];
                    // Extraemos los grupos del combo
                    const comboGroups = element.comboGroups.map(group => {
                        let comboGroupProducts = []
                        // Extraemos los productos de cada grupo
                        group.menuItems.map(menuItem => {
                            let refComboGroupProductPrice = 0
                            let product = simphonyMenu.menuItems.find(product => product.menuItemId === menuItem.menuItemRef);
                            //We get the prices of the product
                            const productPrices = Array.from(
                                new Set(product.definitions
                                    .filter(definition => definition.prices)
                                    .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name ? price.name : null, priceSequence: price.priceSequence }))))
                            )
                            //We checkc if the product has more than one price 
                            if (productPrices.length > 0 && group.isMainGroup) {
                                let index = productPrices.findIndex(price =>  price.priceSequence === 1);
                                //If we dont find any product we just add the first price 
                                if (index === undefined || index === -1) {
                                    refComboGroupProductPrice = productPrices[0].price;
                                } else {
                                    refComboGroupProductPrice = productPrices[index].price;
                                }
                            } else {
                                refComboGroupProductPrice = 0
                            }
                            comboGroupProducts.push({ external_reference: menuItem.menuItemRef, price: refComboGroupProductPrice});
                        })
                        return {
                            isMainSetGroup: group.isMainGroup ? 1 : 0,
                            name: group.name,
                            count: group.count,
                            external_reference: group.comboGroupId,
                            products: comboGroupProducts
                        }
                    });

                    // Obtenemos el nombre del producto principal (combo) y los impuestos asociados
                    const productName = comboRef ? comboRef.name["en-US"] : comboName;
                    const productTaxes = Array.from(
                        new Set(comboRef ? comboRef.definitions.map(definition => ({ taxClassRef: definition.taxClassRef })) : [])
                    );
                    const familyGroupId = comboRef ? comboRef.familyGroupRef : null;

                    console.log(productName, comboId, productTaxes)
                    //We add the taxes 
                    if (productTaxes.length > 0) {
                        productTaxClass = simphonyTaxes.taxClasses.find(element => element.taxClassId === productTaxes[0].taxClassRef);
                    }

                    //We check if extist any tax class
                    if (productTaxClass !== undefined && productTaxClass !== null) {
                        //Fro the product tax class we ge the active tax rate refs 
                        productTaxClass.activeTaxRateRefs.map(tax => {
                            let taxRate = integrationTaxes.find(element => element.taxRateId == tax)
                            productSmartpayTaxes.push({ tax_id: taxRate ? taxRate.smartpayTaxId : 4 })
                        })
                    } else {
                        productSmartpayTaxes.push({ tax_id: 4 })
                    }

                    // Estructuramos el combo para el formato de bulk insert
                    const formattedCombo = {
                        name: productName,
                        taxes: productSmartpayTaxes,  // Mapeamos los impuestos al formato requerido
                        external_reference: comboId,
                        menuItemRef: element.menuItemRef,
                        setGroups: comboGroups
                    };

                    // Añadimos el combo al array de combos formateados
                    combos.push(formattedCombo);
                    //We call the function to add the combo and categories
                    addComboAndCategories(comboId, familyGroupId);
                }
            });


            console.log(products)
            console.log("categorias", categories)
            console.log(segments)
            console.log(items)
            console.log(combos)

            //Request to insert menu on bulk 
            const res = await GroupsFechtAPI.insertMenuOnBulk(branchoffice.id, products, items, segments, categories, pos_integration_id, combos, token);
            //Success message 
            SuccessAlert("Menu sincronizado correctamente");
            //We set the syncronized to true
            setSyncronized(1);
        } catch (err) {
            console.log(err)
            //Error alert 
            ErrorAlert(err)
        }
    }

    return (
        <div>
            {/** Button to syncronice menu */}
            <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                <button onClick={() => (setShowModal(true), getSimphonyMenus())} type="button" className="btn btn-success px-5">Sincronizar menu</button>
            </div>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header className='text-center'>
                    <h3>
                        Integracion habilitada: Simphony V19.5
                    </h3>

                </Modal.Header>
                <Modal.Body>
                    {/**We make a validation depending if the user already select a branchoffice */}
                    {branchoffice.code !== 0 ?
                        <>
                            Sucursal a sincronizar: {branchoffice.name}
                            <Form>
                                <Form.Group className="mb-3" controlId="clientId">
                                    <Form.Label>Seleccione menu a sincronizar</Form.Label>
                                    <Form.Select aria-label="" name="simphonyMenus" onChange={e => (getSimphonyMenuById(e.target.value), getSimphonyTaxes(), getSmartpayTaxes())}>
                                        <option value={null}>Seleccione...  </option>
                                        {simphonyMenus.map(item => (
                                            <option value={item.menuId}>{`ID: ${item.menuId} - name:${item.name}`}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <div className="mt-3 d-flex justify-content-end">
                                    <Button size="sm" variant="success" type="button" className='m-3' onClick={() => (console.log(simphonyMenu),
                                        LoadingAlert('Sincronizando menu...', 'Espere por favor...'), insertMenuOnBulk()
                                    )} >
                                        Guardar
                                    </Button>
                                </div>
                            </Form>
                        </>
                        : "Seleccione una sucursal"}

                </Modal.Body>
            </Modal>
        </div>
    )
}
