//React components 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';

//Components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import SmartPayFormModal from '../Modal/Modal';
import ModalDetailNotification from './ModalDetailNotification';
import ClientNotifications from './ClientNotifications';
//Alerts
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
//API 
import { NotificationsFetchAPI } from '../../api/Notifications';

//Notification component
const Notifications = () => {
    //Params 
    let { business_code } = useParams();
    //LocalStorage
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States
    const [selectedNotification, setSelectedNotification] = useState({
        description: "",
        secure_url: "",
        name: "",
        id: 0,
        branchoffices: []
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    const [notifications, setNotifications] = useState([]);
    //Forms states 
    const [formData, setFormData] = useState({});
    const [modalFields, setModalFields] = useState([
        { name: "name", type: "text", label: "Nombre", placeholder: "Ingrese nombre", required: true },
        { name: "description", type: "text", label: "Descripcion", placeholder: "Ingrese descripcion", required: true },
        { name: "files", type: "file", label: "Imagen", placeholder: "Seleccione imagen", required: false },
    ]);
    //Modal states 
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showEditNotification, setShowEditNotificationModal] = useState(false);

    //Function to get the notifications types 
    const getNotificationsTypes = async (branchoffice_id, business_id) => {
        try {
            //We get the notifications types
            const res = await NotificationsFetchAPI.getTypeNotifications(business_id, branchoffice_id, token);
            setNotifications(res.data.notification_types)
        } catch (err) {
            setNotifications([])
        }
    }

    //Function to create a new notification type
    const createNotificationType = async (e) => {
        e.preventDefault();
        try {
            //We add the business id to the form data
            formData.business_id = user.business_id;
            //We create the notification type
            await NotificationsFetchAPI.createNotificationType(formData, token);
            //We show the success alert
            SuccessAlert('Notificacion creada');
            //We set the event handler
            setEventHandler(1);
            //We close the modal
            setShowAddModal(false);
            //We reset the form data
            setModalFields([
                { name: "name", type: "text", label: "Nombre", placeholder: "Ingrese nombre", required: true },
                { name: "description", type: "text", label: "Descripcion", placeholder: "Ingrese descripcion", required: true },
                { name: "files", type: "file", label: "Imagen", placeholder: "Seleccione imagen", required: false },
            ]);
        } catch (err) {
            console.log(err)
            //We show the error alert
            ErrorAlert('Error al crear la notificacion');
        }
    }

    //We get the branchoffices of a selected notification
    const getBranchofficesByNotification = async (notification) => {
        try {
            console.log(notification)
            //We get the branchoffices
            const res = await NotificationsFetchAPI.getBranchofficesByNotificationTypes(notification.id, token);
            //We set the selected notification
            setSelectedNotification({
                description: notification.description,
                secure_url: notification.secure_url,
                name: notification.name,
                id: notification.id,
                branchoffices: res.data.branchoffices
            })
        } catch (err) {
            setSelectedNotification({
                description: notification.description,
                secure_url: notification.secure_url,
                name: notification.name,
                id: notification.id,
                branchoffices: []
            })
        }
    }

    //Function to edit the notification  type
    const editNotificationType = async (e) => {
        e.preventDefault();
        try {
            //We add the notification id to the form data
            formData.notification_id = selectedNotification.id;
            //We edit the notification type
            await NotificationsFetchAPI.editNotificationType(formData, token);
            //We show the success alert
            SuccessAlert('Notificacion editada');
            //We set the event handler
            setEventHandler(1);
            //We close the modal
            setShowEditNotificationModal(false);
        } catch (err) {
            //We show the error alert
            ErrorAlert('Error al editar la notificacion');
        }
    }

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        getNotificationsTypes(0, user.business_id)
        setEventHandler(0)
        localStorage.setItem('branchoffice_selected', 0)
    }, []);

    //Hook to get the machines of the branch office
    useEffect(() => {
        getNotificationsTypes(0, user.business_id)
        setEventHandler(0)
    }, [eventHandler]);

    //Hook to change the initial form data 
    useEffect(() => {
        // Set initial form values for the edit form
        setFormData({
            name: selectedNotification.name,
            description: selectedNotification.description,
            secure_url: selectedNotification.secure_url,
            branchoffices: selectedNotification.branchoffices
        });
    }, [selectedNotification])

    return (
        <div className='sp-grid bg-white no-scroll position-relative g-0'>
            <div className="fixed-column">
                <NavBar titlePage={"Notificaciones"} />
            </div>
            <div className='other-column ' />
            {/** Main container */}
            <div className="m-2">
                {/** Banner */}
                <section className='banner-reports d-flex d-lg-flex justify-content-lg-between align-items-center
                     mb-4 banner-sp rounded-4 py-2 px-3 '>
                    <div>
                        <div className='d-flex justify-content-lg-between align-items-center'>
                            <h2 className='banner-reports-title mb-0 pb-0'>
                                Notificaciones
                            </h2>
                        </div>
                        <p className='m-0 p-0 banner-reports-description'>Gestion de Notificaciones.</p>
                    </div>

                </section>


                <Tab.Container defaultActiveKey="notifiaction_types">
                    <Row>
                        <Col sm={12}>
                            <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                <li className="nav-sp-item">
                                    <Nav.Link eventKey="notifiaction_types" title="Tipos de notificaciones" ><i className="uil uil-bell"></i>Tipos de Notificaciones</Nav.Link>
                                </li>
                                <li className="nav-sp-item"  >
                                    <Nav.Link eventKey="client_notifications" ><i className="uil uil-bell-school"></i> Notificaciones de los clientes</Nav.Link>
                                </li>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="notifiaction_types">
                                    <div className="m-0">
                                        <div className='d-flex justify-content-between'>
                                            <h4><><i className="uil uil-sign-in-alt"></i> Tipos de notificationes</></h4>

                                            {/** Modal to add a new notification type*/}
                                            <SmartPayFormModal
                                                formType={"addform"}
                                                button_text={"Agregar Tipo de Notificación"}
                                                buttonModal={true}
                                                modal_header_text={"Agregar Notificación"}
                                                modal_body={modalFields}
                                                initialFormValues={modalFields}
                                                setShowModal={setShowAddModal}
                                                showModal={showAddModal}
                                                handleFieldChange={handleFieldChange}
                                                submit={createNotificationType} />
                                        </div>


                                        {/** Table */}
                                        <Table
                                            table_headers={['Imagen', 'Nombre', 'Descripcion', "Acciones"]}
                                            table_data={notifications}
                                            table_type={"notification_types_table"}
                                            setShowModal={setShowDetailModal}
                                            setSelectedNotification={setSelectedNotification}
                                            setEditShowModal={setShowEditNotificationModal}
                                            getBranchofficesByNotification={getBranchofficesByNotification}
                                        />
                                        {/** Modal detail notification */}
                                        <ModalDetailNotification
                                            setShowModal={setShowDetailModal}
                                            showModal={showDetailModal}
                                            notification={selectedNotification}
                                            userBranchoffices={userBranchoffices}
                                            setEventHandler={setEventHandler}
                                            token={token}
                                        />
                                        {/**Edit user modal*/}
                                        <SmartPayFormModal
                                            buttonModal={false}
                                            modal_size={"lg"}
                                            modal_header_text={"Editar notificaiones"}
                                            modal_body={modalFields}
                                            submit={editNotificationType}
                                            setShowModal={setShowEditNotificationModal}
                                            showModal={showEditNotification}
                                            handleFieldChange={handleFieldChange}
                                            initialFormValues={formData}
                                            formType={"editForm"}
                                        />
                                    </div>

                                </Tab.Pane>
                                <Tab.Pane eventKey="client_notifications">
                                    <div className="m-0">
                                        <ClientNotifications
                                            userBranchoffices={userBranchoffices}
                                            business_code={business_code}
                                            token={token}
                                            user={user}
                                        />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>

                    </Row>
                </Tab.Container>



            </div>
        </div>
    )
}


export default Notifications;
