//React Components 
import React, { useState, useEffect, useMemo } from 'react';
//Components
import { Table, Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import ModalCreateItem from './ModalItems.jsx/ModalCreateItem';
import ModalEditItem from './ModalItems.jsx/ModalEditItem';
import ModalViewItem from './ModalItems.jsx/ModalViewItem';
import ModalImport from './ModalImport';
import Swal from 'sweetalert2';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
import ModalAsingAndRemovedItemToBranchoffice from './ModalItems.jsx/ModalAsingAndRemovedItemToBranchoffice';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
//API
import { ItemsFechtAPI } from '../../api/Items';

//icons
import trash from '../img/icons/delete.png'

const ItemTable = ({ branchoffice_id, eventHandler, token, title, user }) => {
    const [items, setItems] = useState([]);
    const [searchedItems, setSearchedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // We load the items list 
        loadItems();
    }, [eventHandler]);

    //Function to load items
    const loadItems = async () => {
        try {
            //We search the items of the branchoffice 
            const items = await ItemsFechtAPI.getItemsByBranchoffice(branchoffice_id, token);
            console.log(items)
            setItems(items.data.data)
            setSearchedItems(items.data.data)
        } catch (err) {
            setItems([])
            setSearchedItems([])
        }
    };

    //Filter the items by the term searched 
    const filteredItems = useMemo(() => {
        if (searchTerm === '') {
            return items
        }

        return searchedItems.filter(item => {
            const nameMatch = item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = item.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, items]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked,
            item_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await ItemsFechtAPI.updateItemBranchoffice(branchoffice_id, data, token)
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los items al cambiar el estado del switch
            loadItems();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error, 'Opss!', 'warning');
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los items al cambiar el estado del switch
            loadItems();
        }
    };

    const beforeDelete = async (item_id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar este producto individual de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItemToBranchoffice(item_id)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino el producto individual',
                    'error'
                )
            }
        })
    }

    const deleteItemToBranchoffice = async (item_id) => {
        let data = {
            "item_id": item_id
        }
        try {
            const response = await ItemsFechtAPI.deleteItemToBranchoffice(branchoffice_id, data, token)
            ErrorAlert('', 'El producto individual fue eliminado correctamente', 'success')
            loadItems()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar el producto individual', 'error')
        }
    }



    const viewItem = () => {

        const list = filteredItems.map(item => (
            <Col xs="12" sm="6" md="6" lg="4" xl="3">
                <article className={item.status === 1 ? 'card-on shadow-sm rounded-4 mt-4 p-2' : 'border rounded-4 mt-4 p-2'} >

                    <div
                        className="d-flex justify-content-between align-items-start "
                    >
                        <img
                            className='imgitemsList  border'
                            src={item.img}
                            alt={item.name}
                        />
                        <div className="ms-1 me-auto d-inline-block">
                            <div className="fw-bold text-truncate card-item-categorie-title">{item.name}</div>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{item.description}</p>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                        <div className='d-flex justify-content-between align-items-center rounded-3 mt-1 gap-1'>
                            <div className=''>
                                {/**Validation to delete items */}
                                <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_items', user.permissions) !== -1}>
                                    <button className='btn btn-outline-light  pulse rounded-3 p-2 lh-1 border' onClick={async () => beforeDelete(item.item_id)}>
                                        <img className='icon-options-cards' src={trash} alt="Delete" />
                                    </button>
                                </RenderIf>
                            </div>

                            {/**Validation to edit items */}
                            <RenderIf isTrue={checkUserPermission('update_items', user.permissions) !== -1}>
                                <div className=''><ModalEditItem user={user}item={item} loadItems={loadItems}></ModalEditItem></div>
                            </RenderIf>
                            <div className=''><ModalViewItem item={item} ></ModalViewItem></div>
                        </div>

                        <div>
                            <RenderIf isTrue={checkUserPermission('enable_disable_items', user.permissions) !== -1}>
                                <div className='d-flex justify-constent-center align-items-center'>
                                    {/**Validation if the user can enable disable items */}
                                    <RenderIf isTrue={checkUserPermission('enable_disable_items', user.permissions) !== -1 && branchoffice_id !== 0}>
                                        <Form.Switch
                                            type="switch"
                                            id={item.item_id}
                                            label=""
                                            checked={item.status === 1 ? true : false}
                                            onChange={handleSwitchChange}
                                        />
                                    </RenderIf>
                                    <RenderIf isTrue={branchoffice_id !== 0}>
                                        {item.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                                    </RenderIf>
                                </div>
                                {/* <input type="checkbox" className="btn-check" autocomplete="off"
                                    id={item.item_id}
                                    label=""
                                    checked={item.status === 1 ? true : false}
                                    onChange={handleSwitchChange}
                                /> */}

                                {/* {item.status  === 1
                                    ? <> <label className="icon-on pulse rounded-3 p-2 lh-1 border-on" for={item.item_id}>
                                        <img className='icon-options-cards' src={on} alt="restart" />
                                        <small className='ms-1 text-on fw-semibold'>Activo</small>
                                    </label>
                                    </>
                                    : <>
                                        <label className="btn bg-light pulse rounded-3 p-2 lh-1 border opacity-75" for={item.item_id} >
                                            <img className='icon-options-cards' src={off} alt="restart" />
                                            <small className='ms-1 text-secondary'>Inactivo</small>
                                        </label>
                                    </>} */}
                            </RenderIf>
                        </div>
                    </div>
                </article>


                {/* <ListGroup as="ol" className='' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList '
                            src={item.img}
                            alt={item.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{item.name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>                               
                                <RenderIf isTrue={checkUserPermission('enable_disable_items', user.permissions) !== -1}>
                                    <Form.Switch
                                        type="switch"
                                        id={item.item_id}
                                        label=""
                                        checked={item.status === 1 ? true : false}
                                        onChange={handleSwitchChange}
                                    />
                                </RenderIf>
                                {item.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>

                            <p className=''>{item.description}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className=''><ModalViewItem item={item} ></ModalViewItem></div>
                          
                                <RenderIf isTrue={checkUserPermission('update_items', user.permissions) !== -1}>
                                    <div className=''><ModalEditItem item={item} loadItems={loadItems}></ModalEditItem></div>
                                </RenderIf>
                                <div className=''>                          
                                    <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_items', user.permissions) !== -1}>
                                        <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => beforeDelete(item.item_id)}>
                                            <i className="uil uil-trash-alt"></i>
                                        </button>
                                    </RenderIf>
                                </div>
                            </div>
                        </div>


                    </ListGroup.Item>
                </ListGroup> */}
            </Col>


        ))
        return (list)
    }

    return (
        <>
            <div className='mb-5'>
                <div className="row  mx-1 mt-3 bg-section-filter-items border rounded-3 py-2 p-lg-4  sticky-lg-top">
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className=' fs-5 fw-bold text-left text-white'>
                                {title}
                                <span className='badge rounded-pill bg-secondary-subtle text-dark shadow-sm rounded-pill mx-1'>
                                    {items.length}
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4  p-1 order-2 order-lg-1 order-md-2">
                        <div className="d-flex justify-content-md-center justify-content-center">
                            <form className="w-75 mx-2">
                                <div className="col-auto ">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>




                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-4 p-1 order-1 order-lg-2 order-md-1">
                        <div className='d-flex  justify-content-md-end justify-content-lg-end  justify-content-center align-items-center gap-1 p-1 '>
                            {/**Validation if the user can create items */}
                            <RenderIf isTrue={checkUserPermission('create_items', user.permissions) !== -1}>
                                <div className=''> <ModalCreateItem user = {user}loadItems={loadItems}></ModalCreateItem></div>
                                <div className=''><ModalImport title={"producto individual"} load={loadItems}></ModalImport></div>
                            </RenderIf>
                            {/**Validation to asing item to branchoffice */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_items', user.permissions) !== -1}>
                                <div className=''>
                                    <ModalAsingAndRemovedItemToBranchoffice branchoffice_id={branchoffice_id} load={loadItems} itemsBranchoffice={items}></ModalAsingAndRemovedItemToBranchoffice>
                                </div>
                            </RenderIf>

                        </div>
                    </div>

                </div>

                <div className="mt-0 m-0 p-0">
                    <Row>
                        {viewItem()}
                    </Row>
                </div>
            </div>
        </>
    );
};

export default ItemTable;
