import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CouponPosFetchAPI {
    static async getCupon(cupon_id, token) {
        const res = await server(`/cupon/${cupon_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getCuponsByBusiness(business_id, token){ 
        const res = await server(`/cupon/${business_id}/business`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async newCupon(data, token) {
        const res = await server(`/cupon`, {
            method: "post",
            headers: { 'x-access-token': token, multipart: 'form-data' },
            data: data
        });
        return res
    }

    static async updateCupon(cupon_id, data, token) {
        const res = await server(`/cupon/${cupon_id}`, {
            method: "put",
            headers: { 'x-access-token': token, multipart: 'form-data' },
            data: data
        });
        return res
    }

    static async addProductToCupon(cupon_id, group_id, token) {
        const res = await server(`/cupon/${cupon_id}/group`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: { group_id: group_id }
        });
        return res
    }

    static async removeProductFromCupon(cupon_id, group_id, token) {
        const res = await server(`/cupon/${cupon_id}/group`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: { group_id: group_id }
        });
        return res
    }

    static async addComboToCupon(cupon_id, combo_id, token) {
        const res = await server(`/cupon/${cupon_id}/combo`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: { combo_id: combo_id }
        });
        return res
    }

    static async removeComboFromCupon(cupon_id, combo_id, token) {
        const res = await server(`/cupon/${cupon_id}/combo`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: { combo_id: combo_id }
        });
        return res
    }

    static async addCategorieToCupon(cupon_id, category_id, token) {
        const res = await server(`/cupon/${cupon_id}/category`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: { category_id: category_id }
        });
        return res
    }
    

    static async removeCategorieFromCupon(cupon_id, category_id, token) {
        const res = await server(`/cupon/${cupon_id}/category`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: { category_id: category_id }
        });
        return res
    }


    static async addBranchOfficeToCupon(cupon_id, branchOffice_id, token) {
        const res = await server(`/cupon/${cupon_id}/branchoffice`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: { branchoffice_id: branchOffice_id }
        });
        return res
    }

    static async removeBranchOfficeFromCupon(cupon_id, branchOffice_id, token) {
        const res = await server(`/cupon/${cupon_id}/branchoffice`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: { branchoffice_id: branchOffice_id }
        });
        return res
    }

    static async addConditionCupon(cupon_id, type, value, token) {
        const res = await server(`/cupon/${cupon_id}/conditions`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: { type: type, value: value }
        });
        return res
    }

    static async removeConditionCupon(cupon_id, condition_id, token) {
        const res = await server(`/cupon/${cupon_id}/conditions`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: { condition_id: condition_id }
        });
        return res
    }

}