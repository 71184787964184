//React components
import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, ListGroup, Col, Row, Alert } from 'react-bootstrap';
//Components 
import ModalForm from '../../Modal/Modal';
import ModalAsingProductCupon from './ModalAsingProductCupon';
import ModalAsingCombosCupon from './ModalAsingCombosCupon';
import ModalAsingCategoriesCupon from './ModalAsingCategoriesCupon';
import ModalAsingBranchOfficeCupon from './ModalAsingBranchOfficeCupon';

//Image 
import defaultImage from "../../../assets/img/default.png";
//API 
import { CouponPosFetchAPI } from '../../../api/Coupon';
//utils 
import { RenderIf } from '../../utils/RenderIf';
//Alerts
import AlertError from '../../Alerts/ErrorAlert';
import Swal from 'sweetalert2';
import ModalAsingDayCupon from './ModalAsingDayCupon';
import ModalAsingHoursCupon from './ModalAsingHoursCupon';
//Function to show the detail of the cupons
const ModalDetailCupons = ({ cupon, groups, combos, categories, showModal, setShowModal, user }) => {
    //States
    const [detailCupon, setDetailCupon] = useState({ days: [], maxDiscount: 0, minAmount: 0, expirationDate: "No definido", hours: [], img: "", name: "", description: "", discount_value: 0, discount_type: "FIXED", type: "PRODUCT", conditions: [], categories: [], combos: [], groups: [], usage: [], branchoffice: [] })
    const [eventHandler, setEventHandler] = useState(0);
    //Internal modals 
    const [showProductModal, setShowProductModal] = useState(false)
    const [showComboModal, setShowComboModal] = useState(false);
    const [showCategorieModal, setShowCategorieModal] = useState(false);
    const [showBranchOfficeModal, setShowBranchOfficeModal] = useState(false);
    const [showDayModal, setShowDayModal] = useState(false);
    const [showHourModal, setShowHourModal] = useState(false);
    //Function to get the detail of the cupon
    const getDetailCupon = async (cupon) => {
        try {
            const response = await CouponPosFetchAPI.getCupon(cupon, localStorage.getItem('x-access-token'));
            //We get the days that this cupon is available
            let days = response.data.data.conditions.filter((condition) => condition.condition_type === "DAY")
            //We get the hours that this cupon is available
            let hours = response.data.data.conditions.filter((condition) => condition.condition_type === "TIME")
            //We get the max discount that this cupon has
            let maxDiscount = response.data.data.conditions.filter((condition) => condition.condition_type === "max_discount")
            //We get the min amount that this cupon has
            let minAmount = response.data.data.conditions.filter((condition) => condition.condition_type === "min_order_value")
            //We get the expiration date of the cupon
            let expirationDate = response.data.data.conditions.filter((condition) => condition.condition_type === "expiration_date")

            console.log(response.data.data)
            //We set the info
            setDetailCupon({
                ...response.data.data,
                days: days,
                hours: hours,
                maxDiscount: maxDiscount && maxDiscount.length > 0 ? maxDiscount[0].condition_value : "No definido",
                minAmount: minAmount && minAmount.length > 0 ? minAmount[0].condition_value : "No definido",
                expirationDate: expirationDate && expirationDate.length > 0 ? expirationDate[0].condition_value : "No definido"
            })
        } catch (err) {

        }
    }

    //Function to remove a product from the cupon
    const removeProductFromCupon = async (product) => {
        try {
            Swal.fire({
                title: '¿Estas seguro?',
                text: "No podras revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let res = await CouponPosFetchAPI.removeProductFromCupon(cupon, product, localStorage.getItem('x-access-token'));
                        AlertError('Producto removido correctamente', 'Exito', 'success')
                        setEventHandler(1)
                    } catch (err) {
                        AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar el producto del cupon', 'error', 'error')
                    }
                }
            });
        } catch (err) {
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar el producto del cupon', 'error', 'error')
        }
    }

    //Function to remove a combo from the cupon
    const removeComboFromCupon = async (combo) => {
        try {
            Swal.fire({
                title: '¿Estas seguro?',
                text: "No podras revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let res = await CouponPosFetchAPI.removeComboFromCupon(cupon, combo, localStorage.getItem('x-access-token'));
                        AlertError('Combo removido correctamente', 'Exito', 'success')
                        setEventHandler(1)
                    } catch (err) {
                        AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar el combo del cupon', 'error', 'error')
                    }
                }
            });
        } catch (err) {
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar el combo del cupon', 'error', 'error')
        }
    }

    //Function to remove a category from the cupon
    const removeCategoryFromCupon = async (category) => {
        try {
            Swal.fire({
                title: '¿Estas seguro?',
                text: "No podras revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await CouponPosFetchAPI.removeCategorieFromCupon(cupon, category, localStorage.getItem('x-access-token'));
                        AlertError('Categoria removida correctamente', 'Exito', 'success')
                        setEventHandler(1)
                    } catch (err) {
                        AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar la categoria del cupon', 'error', 'error')
                    }
                }
            });
        } catch (err) {
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar la categoria del cupon', 'error', 'error')
        }
    }

    //Function to remove a branch office from the cupon
    const removeBranchOfficeFromCupon = async (branch) => {
        try {
            Swal.fire({
                title: '¿Estas seguro?',
                text: "No podras revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await CouponPosFetchAPI.removeBranchOfficeFromCupon(cupon, branch, localStorage.getItem('x-access-token'));
                        AlertError('Sucursal removida correctamente', 'Exito', 'success')
                        setEventHandler(1)
                    } catch (err) {
                        AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar la sucursal del cupon', 'error', 'error')
                    }
                }
            });
        } catch (err) {
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar la sucursal del cupon', 'error', 'error')
        }
    }

    //Function to remove a condition from the cupon 
    const removeConditionFromCupon = async (condition) => {
        try {
            Swal.fire({
                title: '¿Estas seguro?',
                text: "No podras revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await CouponPosFetchAPI.removeConditionCupon(cupon, condition, localStorage.getItem('x-access-token'));
                        AlertError('Condicion removida correctamente', 'Exito', 'success')
                        setEventHandler(1)
                    } catch (err) {
                        AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar la condicion del cupon', 'error', 'error')
                    }
                }
            });
        } catch (err) {
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al eliminar la condicion del cupon', 'error', 'error')
        }
    }
    //UseEffect
    useEffect(() => {
        if (showModal) {
            getDetailCupon(cupon)
        }
    }, [showModal]);

    //UseEffect to get the detail of the cupon
    useEffect(() => {
        if (eventHandler === 1) {
            getDetailCupon(cupon)
            setEventHandler(0)
        }
    }, [eventHandler]);

    return (
        <div>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle del cupon  </h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={detailCupon.img ? detailCupon.img : defaultImage}
                            alt=""
                        />
                    </div>
                    <Container>
                        <div className='mt-3'>
                            <Row>
                                <Col>
                                    <ListGroup as="ol" >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Nombre</div>
                                                {detailCupon.name}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Tipo de descuento del cupon</div>
                                                {detailCupon.discount_type === "FIXED" ? "Fijo" : detailCupon.discount_type === "PERCENTAGE" ? "Porcentaje" : "Producto gratis"}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Cantidad aplicable</div>
                                                {detailCupon.discount_type === "FIXED" ? "$" : ""}{detailCupon.discount_value.toFixed(2)}{detailCupon.discount_type === "PERCENTAGE" ? "%" : ""}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Aplica</div>
                                                {detailCupon.type === "PRODUCT" ? "Productos" : "Orden completa"}
                                            </div>

                                        </ListGroup.Item>
                                        {/** product condition */}
                                        <RenderIf isTrue={detailCupon.type === "PRODUCT"}>
                                            {/** Productos */}
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Productos donde aplica
                                                        <button className='btn btn-primary btn-sm border-0 m-2' onClick={e => setShowProductModal(true)} >
                                                            <i className="uil uil-plus">Agregar Producto</i>
                                                        </button>
                                                    </div>
                                                    <ListGroup as="ol" >
                                                        {detailCupon.groups.map((group, index) => (
                                                            <ListGroup.Item as="li" key={index} className="d-flex justify-content-between align-items-start">
                                                                <div className="ms-2 me-auto" >
                                                                    {group.name}
                                                                    <button className='btn btn-outline-danger btn-sm border-0' onClick={e => removeProductFromCupon(group.group_id)} >
                                                                        <i className="uil uil-trash-alt"></i>
                                                                    </button>
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))}
                                                    </ListGroup>

                                                </div>
                                            </ListGroup.Item>
                                            {/** Combos */}
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Combos donde aplica
                                                        <button className='btn btn-primary btn-sm border-0 m-2' onClick={e => setShowComboModal(true)}>
                                                            <i className="uil uil-plus">Agregar Combo</i>
                                                        </button>
                                                    </div>
                                                    <ListGroup as="ol" >
                                                        {detailCupon.combos.map((combo, index) => (
                                                            <ListGroup.Item as="li" key={index} className="d-flex justify-content-between align-items-start">
                                                                <div className="ms-2 me-auto" >
                                                                    {combo.name}
                                                                    <button className='btn btn-outline-danger btn-sm border-0' onClick={e => removeComboFromCupon(combo.combo_id)}>
                                                                        <i className="uil uil-trash-alt"></i>
                                                                    </button>
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))}
                                                    </ListGroup>

                                                </div>
                                            </ListGroup.Item>
                                        </RenderIf>
                                        {/** Monto minimo  */}
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Monto minimo de compra</div>
                                                ${detailCupon.minAmount}
                                            </div>
                                        </ListGroup.Item>
                                        {/** Monto maximo de descuento */}
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Monto maximo de descuento</div>
                                                ${detailCupon.maxDiscount}
                                            </div>
                                        </ListGroup.Item>

                                        {/** Fecha de expiracion */}
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Fecha de expiracion</div>
                                                {detailCupon.expirationDate}
                                            </div>
                                        </ListGroup.Item>


                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Descripción</div>
                                                {detailCupon.description}
                                            </div>
                                        </ListGroup.Item>

                                    </ListGroup>
                                </Col>
                                <Col>
                                    {/** Categories */}
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Categorias
                                                <button className='btn btn-primary btn-sm border-0 m-2' onClick={e => setShowCategorieModal(true)}>
                                                    <i className="uil uil-plus">Agregar categoria</i>
                                                </button>
                                            </div>
                                            <ListGroup as="ol" >
                                                {detailCupon.categories.map((category, index) => (
                                                    <ListGroup.Item as="li" key={index} className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            {category.name}
                                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={e => removeCategoryFromCupon(category.category_id)}>
                                                                <i className="uil uil-trash-alt"></i>
                                                            </button>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>

                                        </div>
                                    </ListGroup.Item>
                                    {/** Sucursales */}
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Sucursales donde aplica
                                                <button className='btn btn-primary btn-sm border-0 m-2' onClick={e => setShowBranchOfficeModal(true)} >
                                                    <i className="uil uil-plus">Agregar Sucursal</i>
                                                </button>
                                            </div>
                                            <ListGroup as="ol" >
                                                {detailCupon.branchoffice.map((branch, index) => (
                                                    <ListGroup.Item as="li" key={index} className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            {branch.name}
                                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={e => removeBranchOfficeFromCupon(branch.branchoffice_id)}>
                                                                <i className="uil uil-trash-alt"></i>
                                                            </button>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>

                                        </div>
                                    </ListGroup.Item>
                                    {/** Days  */}
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Dias de la semana
                                                <button className='btn btn-primary btn-sm border-0 m-2' onClick={e => setShowDayModal(true)} >
                                                    <i className="uil uil-plus">Agregar Dia</i>
                                                </button>
                                            </div>
                                            <ListGroup as="ol" >
                                                {detailCupon.days.map((day, index) => (
                                                    <ListGroup.Item as="li" key={index} className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            {day.condition_value === "MON" ? "Lunes" : day.condition_value === "TUE" ? "Martes" : day.condition_value === "WED" ? "Miercoles" : day.condition_value === "THU" ? "Jueves" : day.condition_value === "FRI" ? "Viernes" : day.condition_value === "SAT" ? "Sabado" : "Domingo"}
                                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={e => removeConditionFromCupon(day.id)}>
                                                                <i className="uil uil-trash-alt"></i>
                                                            </button>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>

                                        </div>
                                    </ListGroup.Item>
                                    {/** Hours */}
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Horas del dia
                                                <button className='btn btn-primary btn-sm border-0 m-2' onClick={e => setShowHourModal(true)} >
                                                    <i className="uil uil-plus">Agregar Hora</i>
                                                </button>
                                            </div>
                                            <ListGroup as="ol" >
                                                {detailCupon.hours.map((hour, index) => (
                                                    <ListGroup.Item as="li" key={index} className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            {hour.condition_value}
                                                            <button className='btn btn-outline-danger btn-sm border-0'  onClick={e => removeConditionFromCupon(hour.id)}>
                                                                <i className="uil uil-trash-alt"></i>
                                                            </button>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>
                                    </ListGroup.Item>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/** Modal to add a product */}
            <ModalAsingProductCupon
                showModal={showProductModal}
                setShowModal={setShowProductModal}
                groups={groups}
                setEventHandler={setEventHandler}
                selectedCupon={cupon}
            />
            {/** Modal to add a combo */}
            <ModalAsingCombosCupon
                showModal={showComboModal}
                setShowModal={setShowComboModal}
                combos={combos}
                setEventHandler={setEventHandler}
                selectedCupon={cupon}
            />
            {/** Modal to add a categorie */}
            <ModalAsingCategoriesCupon
                showModal={showCategorieModal}
                setShowModal={setShowCategorieModal}
                categories={categories}
                selectedCupon={cupon}
                setEventHandler={setEventHandler}
            />
            {/** Modal to add a branchoffice  */}
            <ModalAsingBranchOfficeCupon
                showModal={showBranchOfficeModal}
                setShowModal={setShowBranchOfficeModal}
                selectedCupon={cupon}
                user={user}
                setEventHandler={setEventHandler}
            />
            {/** Modal to add a day */}
            <ModalAsingDayCupon
                showModal={showDayModal}
                setShowModal={setShowDayModal}
                selectedCupon={cupon}
                setEventHandler={setEventHandler}
            />
            {/** Modal to add a hour */}
            <ModalAsingHoursCupon
                showModal={showHourModal}
                setShowModal={setShowHourModal}
                selectedCupon={cupon}
                setEventHandler={setEventHandler}
            />
        </div>
    );
}

export default ModalDetailCupons;
