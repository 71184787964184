import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//API 
import { CouponPosFetchAPI } from '../../../api/Coupon';
//Alerts 
import AlertError from '../../Alerts/ErrorAlert';

//Modal asing categories cupon
const ModalAsingCategoriesCupon = ({ showModal, setShowModal, categories, selectedCupon, setEventHandler }) => {

    //Function to add a categorie to the cupon
    const addCategorieToCupon = async (e) => {
        e.preventDefault();
        const categorie = e.target.categorie.value;
        try {
            let res = await CouponPosFetchAPI.addCategorieToCupon(selectedCupon, categorie, localStorage.getItem('x-access-token'));

            if (res.status === 200) {
                AlertError('Combo asignado correctamente', 'Exito', 'success')
                setEventHandler(1)
            }
        } catch (err) {
            console.log(err)
            AlertError(err.response.data.error !== undefined ? err.response.data.error : 'Error al asignar la categoria al cupon', 'error', 'error')
        }


    }
    return (
        <div>
            <Modal
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asignar cateogira a cupón</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addCategorieToCupon}>
                        <Form.Group controlId="categorie">
                            <Form.Label>Categorie</Form.Label>
                            <Form.Select>
                                <option value={null}>Seleccione categoria...</option>
                                {categories.map((categorie) => (
                                    <option key={"categorie"+categorie.category_id} value={categorie.category_id}>{categorie.category_name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
                            <Button variant="success" type='submit'>Guardar</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>


            </Modal>
        </div>
    );
}

export default ModalAsingCategoriesCupon;
