//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Container } from 'react-bootstrap';
import { RenderIf } from '../utils/RenderIf';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';



const OrderDetailPreOrder = ({ orderData, userBranchoffices, handlerUpdatePreOrder, handleCloseModal, statusDetailModalDinamic, branchOfficeData }) => {
    const [newBranchoffice, setNewBranchoffice] = useState({});

    //Total taxes 
    const [totalTaxes, setTotalTaxes] = useState([
        { total_base: 0, tax: "0%", total_tax: 0 },
        { total_base: 0, tax: "7%", total_tax: 0 },
        { total_base: 0, tax: "10%", total_tax: 0 },
        { total_base: 0, tax: "15%", total_tax: 0 }
    ]);

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={JSON.stringify(branch)}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //put preOrder change branchoffice
    const updatePreOrder = async () => {
        if (newBranchoffice != "0") {
            let token = localStorage.getItem('x-access-token')
            let dataBranchoffice = JSON.parse(newBranchoffice);
            try {

                const responseBranchOffice = await BranchOfficeFetchAPI.getBranchOfficesByid(dataBranchoffice.branchoffice_id, token);
                let dataResponseBranchoffice = responseBranchOffice.data.data.branchoffice;
                let data = {
                    "_id": orderData._id,
                    "groups": orderData.groups,
                    "branchoffice": {
                        "id": dataResponseBranchoffice.branchoffice_id,
                        "code": dataResponseBranchoffice.code,
                        "name": dataResponseBranchoffice.name,
                        "ruc": dataResponseBranchoffice.ruc,
                        "dv": dataResponseBranchoffice.dv
                    }
                }

                const response = await PreOrderFetchAPI.updateOrderTempPreOrder(data, token)
                handleCloseModal();
                handlerUpdatePreOrder();
                ErrorAlert("", "Se asigno la precuenta a la otra sucursal", "success")

            } catch (err) {
                ErrorAlert('', 'Lo sentimos ocurrio un error', 'error');
                console.log(err);
            }
        } else {
            ErrorAlert('', 'Debes seleccionar una sucursal', 'info');
        }

    }

    //We charge the items of the order 
    const orderItems = () => {
        let items = orderData.groups.map((product) => (
            <div className="d-flex text-muted pt-3 px-xs-2" key={product._id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="42" height="42" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{product.name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3'>x {product.amount} </span>
                            <label className="fw-bold" translate='no'>$ {product.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>
                        <div className="d-flex justify-content-between">
                            {/* Name */}

                            {/* Cantidad/ Quantity*/}
                            <div >
                                {/** We check the type of modal and we return the input discount field depending of it */}


                            </div>
                        </div>
                        {product.segments.map((segments) => (
                            <div className="d-flex justify-content-between" key={segments._id}>
                                {/* item name */}
                                <span className="">{segments.item.name}</span>
                            </div>
                        ))}

                        <div>
                            <div>
                                <small className='text-primary p-1' >
                                    Entrega: {product.delivery_type}
                                </small>
                            </div>
                            <div>
                                <RenderIf isTrue={product.note != ""}>
                                    <small className='text-danger p-1' >
                                        Nota: {product.note}
                                    </small>
                                </RenderIf>
                            </div>
                        </div>




                    </>
                </div>
            </div>
        ));
        return <>{items}</>
    }

    return (<>
        <Container className='mb-2'>
            {/* Your card */}
            <Row>
                <Col sm={12} md={12}  >
                    <div className="py-4 px-lg-4 ">
                        <div className="head-section-sp mt-2">
                            <div className="">
                                <div className="mt-1 mb-3">
                                    <div className="d-flex justify-content-between align-items-center mb-3 ">
                                        <div className='header-invoice'>

                                            <div>
                                                <h5 className='p-2'>
                                                    <span className="text-success"> {orderData.order_type} </span>

                                                </h5>
                                            </div>
                                        </div>
                                        <h5>
                                            <span className="text-success">Orden: </span>
                                            <span className="text-secondary"> # {orderData.code ? orderData.code : 0}</span>
                                        </h5>
                                    </div>

                                    <div>
                                        <span className="text-nowrap text-primary h6 d-block mb-2">
                                            <i className="uil uil-user-circle"></i>&nbsp;
                                            Cliente: {orderData.client_name}
                                        </span>
                                        <span className="text-nowrap text-secondary h6 d-block">
                                            <i className="fa-solid fa-store"></i>&nbsp;
                                            Sucursal: {orderData.branchoffice.name}
                                        </span>
                                        <span className="text-nowrap text-primary h6 d-block">
                                            <i className="fa-solid fa-cash-register"></i>&nbsp;
                                            {orderData.machine_name} / {!orderData.user ? 'No es cajera' : orderData.user.name}
                                        </span>
                                        {orderData.paymentInfo ? <>
                                            {orderData.paymentInfo.map((payment) => (
                                                <span className="text-nowrap text-dark h6 d-block">
                                                    <i className="uil uil-bill"> Metodo de pago: </i>
                                                    {payment.name}
                                                </span>
                                            ))}</> : <></>}



                                    </div>
                                </div>
                            </div>

                            <div className=' d-flex justify-content-between text-secondary opacity-75'>
                                <div> Productos </div>
                                <div> Precio </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 order-md-last">
                                    {/* Item cards */}
                                    {orderItems()}
                                    {/**Total details */}
                                    <div className="d-flex text-muted pt-3">
                                        <div className="pb-3 mb-0 small lh-sm border-top w-100">

                                            <div className="d-flex justify-content-end mt-3">
                                                <span className="text-gray-dark fw-light h6"> Descuento: </span>
                                                <span className='text-danger fw-light h6'>
                                                    <span className='px-2 ' translate='no'>- $</span>
                                                    {orderData.discount.toFixed(2)}
                                                </span>
                                            </div>

                                            {/** We render the totals if the order is type permanent */}

                                            <div className="d-flex justify-content-end mt-3">
                                                <span className="text-gray-dark fw-light h6"> Subtotal: </span>
                                                <span className='text-black fw-light h6'>
                                                    <span className='px-2 ' translate='no'>$</span>
                                                    {orderData.subtotal.toFixed(2)}
                                                </span>
                                            </div>
                                            {orderData.taxes.map((tax) => (
                                                <>   <div className="d-flex justify-content-end mt-3">
                                                    <span className="text-gray-dark fw-light h6"> Impuesto {tax.tax}: </span>
                                                    <span className='text-black fw-light h6'>
                                                        <span className='px-2 '>$</span>
                                                        {tax.total.toFixed(2)}
                                                    </span>
                                                </div></>
                                            ))}
                                            <div className="d-flex justify-content-end mt-3">
                                                <strong className="text-black h5"> Total: </strong>
                                                <span className='text-black fw-bold h5'>
                                                    <span className='px-2 ' translate='no'>$ </span>
                                                    {orderData.total.toFixed(2)}
                                                </span>
                                            </div>


                                            <RenderIf isTrue={statusDetailModalDinamic == true}>
                                                <div className='d-flex justify-content-between mt-5'>
                                                    <span className="text-nowrap text-secondary h6 d-block">
                                                        <i className="fa-solid fa-store"></i>&nbsp;
                                                        Cambiar sucursal: <form className='filter-sp-trn'>
                                                            <div className="d-block">
                                                                <select className='form-select' name="user_branch" id="user_branch" onChange={(e) => setNewBranchoffice(e.target.value)}>
                                                                    <option value={0}> Seleccione una sucursal</option>
                                                                    {branchList()}
                                                                </select>
                                                            </div>
                                                        </form>
                                                    </span>
                                                    <button type="button" className="btn btn-primary" onClick={async () => updatePreOrder()}>Guardar</button>
                                                </div>
                                            </RenderIf>


                                            {orderData.paymentInfo ?
                                                <>
                                                    <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
                                                        doc_type={"invoice"} invoiceQR={orderData.dgi_qr} invoiceDate={orderData.createdAt} cufe={orderData.cufe} auth_protocol={orderData.auth_protocol ? orderData.auth_protocol : "00000000"}
                                                        receptor={{
                                                            name: orderData.client_name,
                                                            ruc: orderData.client_ruc,
                                                            direction: "Panama",
                                                            dv: orderData.client_dv,
                                                        }}
                                                        emitter={branchOfficeData}
                                                        products={orderData.groups} invoiceSubtotal={orderData.subtotal} totalTaxes={totalTaxes}
                                                        totalCashReceived={orderData.total} invoiceTotal={orderData.total} invoiceTotalItbms={orderData.tax} paymentMethod={orderData.paymenthod}
                                                    />} >
                                                        <button className='btn btn-secondary-sp border border-sp' >  Descargar factura electronica </button>
                                                    </PDFDownloadLink>
                                                </> : <></>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>

        </Container >
    </>);
}

export default OrderDetailPreOrder;