
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_SYNERGY_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class SynergyFetchAPI {
    
    //Function to insert/update products in the synergy database
    static async insertProducts(data){
        const res = await server(`/products`, {
            method: "post",
            data: data
        });
        return res
    }
    
}

