//React components
import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//Images
import defaultImage from "../../../assets/img/default.png";
//Utils
import { RenderIf } from '../../utils/RenderIf';
//API
import { CouponPosFetchAPI } from '../../../api/Coupon';
//Alerts
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';

// Modal create cupons 
const ModalCreateCupon = ({ user, token, combos, groups, setEventHandler }) => {
    const [showModal, setShowModal] = useState(false);
    const [imgpreview, setImgPreview] = useState(defaultImage);
    const [cuponType, setCuponType] = useState('ORDER');
    const [showDateLimit, setShowDateLimit] = useState(false);
    const [showSpecificHours, setShowSpecificHours] = useState(false);
    const [showSpecificDays, setShowSpecificDays] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [discount_type, setDiscountType] = useState('PERCENTAGE');
    const [hasUsageLimit, setHasUsageLimit] = useState(false);
    const [usageLimit, setUsageLimit] = useState('');
    const [needsUniqueCodes, setNeedsUniqueCodes] = useState(false);
    const [uniqueCodeQuantity, setUniqueCodeQuantity] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const fileInputRef = useRef(null);
    // Function to trigger file input click
    const handleClick = () => {
        fileInputRef.current.click();
    };

    // Handle image preview update
    const changeImage = (e) => {
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    // Handle day selection for specific days
    const handleDaySelection = (day) => {
        setSelectedDays((prevDays) =>
            prevDays.includes(day)
                ? prevDays.filter((d) => d !== day)
                : [...prevDays, day]
        );
    };

    // Handle form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Previene el comportamiento por defecto del formulario

        // Recopilación de los datos del formulario
        const formData = new FormData();

        // Agregamos la imagen del cupón (si se seleccionó)
        if (fileInputRef.current.files[0]) {
            formData.append('files', fileInputRef.current.files[0]);
        }

        // Agregamos los demás campos del formulario
        const couponData = {
            name: e.target.name.value,
            type: cuponType,
            business_id: user.business_id, // Suponiendo que tienes el ID del negocio en `user`
            is_general: needsUniqueCodes ? 1 : 0, // O lo que sea relevante
            discount_type: discount_type,
            discount_value: e.target.discount_value.value,
            description: e.target.description.value,
            min_order_value: cuponType === "ORDER" ? e.target.min_order_value.value : null,
            max_discount: cuponType === "ORDER" ? e.target.max_discount.value : null,
            products: selectedProducts.map(product => ({
                product_id: product.product_id,
                is_combo: product.is_combo
            })),
            conditions: [],
        };

        // Agregar condiciones si son aplicables
        if (showSpecificDays) {
            selectedDays.forEach(day => {
                couponData.conditions.push({ type: 'DAY', value: day });
            });
        }

        if (e.target.usage_limit !== undefined || null) {
            couponData.usage_limit = e.target.usage_limit.value;
        } else { 
            couponData.usage_limit = 0;
        }

        if (e.target.code_count !== undefined || null) {
            couponData.code_count = e.target.code_count.value;
        } else { 
            couponData.code_count = 0;
        }

        if (showSpecificHours) {
            couponData.conditions.push({ type: 'TIME', value: `${e.target.initial_hour.value} - ${e.target.expiration_hour.value}` });
        }

        if (showDateLimit) {
            couponData.conditions.push({ type: 'expiration_date', value: e.target.expiration_date.value });
        }

        if (couponData.min_order_value) {
            couponData.conditions.push({ type: 'min_order_value', value: couponData.min_order_value });
        }

        if (couponData.max_discount) {
            couponData.conditions.push({ type: 'max_discount', value: couponData.max_discount });
        }

        //We add the coupon data to the form data
        formData.append("name", couponData.name);
        formData.append("type", couponData.type);
        formData.append("business_id", couponData.business_id);
        formData.append("is_general", couponData.is_general);
        formData.append("discount_type", couponData.discount_type);
        formData.append("discount_value", couponData.discount_value);
        formData.append("description", couponData.description);
        formData.append("products", JSON.stringify(couponData.products));
        formData.append("conditions", JSON.stringify(couponData.conditions));
        formData.append("usage_limit", couponData.usage_limit);
        formData.append("code_count", couponData.code_count);
        formData.append("min_order_value", couponData.min_order_value);
        formData.append("max_discount", couponData.max_discount);

        try {
            //We send the data to the API
            await CouponPosFetchAPI.newCupon(formData, token);
            //We close the modal
            setShowModal(false);
            //We show a success message
            SuccessAlert("Cupón creado exitosamente");
            //We update the event handler
            setEventHandler(1);
        } catch (err) {
            console.error(err);
            //We show an error message
            ErrorAlert("Error al crear el cupón", "Inténtalo de nuevo", "error");
        }
    };
    return (
        <>
            <button
                className={'btn btn-primary border-0'}
                onClick={() => setShowModal(true)}
            >
                <i className={'uil uil-plus-circle'}></i>
                Crear cupón
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgPreview(defaultImage), setSelectedProducts([]), setDiscountType("PERCENTAGE"))}
                size='xl'
                centered
            >
                <Modal.Body>
                    <div className='text-center p-2'>
                        <h2 className='h4 fw-bold text-primary'>
                            Crear Nuevo cupón
                        </h2>
                    </div>
                    <Form.Label className='text-black fw-semibold'>
                        Imagen de cupón
                    </Form.Label>
                    <div className='border rounded-4' onClick={handleClick}>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img className='imgEditModal' src={imgpreview} alt="Preview" />
                        </div>
                        <div className='d-flex align-items-center justify-content-center m-3'>
                            <h5 className="text-center">Seleccione una imagen</h5>
                        </div>
                    </div>
                    <Form.Group className="mb-3 visually-hidden">
                        <Form.Control
                            type="file"
                            id="upload-button"
                            placeholder="Imagen"
                            name="files"
                            onChange={(e)=>changeImage(e)}
                            ref={fileInputRef}
                        />
                    </Form.Group>

                    <Form onSubmit={handleFormSubmit}>
                        {/* Nombre */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Nombre</Form.Label>
                            <Form.Control type="text" placeholder="Nombre del cupón" name="name" />
                        </Form.Group>

                        {/* Tipo de cupón */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Tipo de cupón</Form.Label>
                            <Form.Select name="type" onChange={(e) => { setCuponType(e.target.value); setDiscountType("PERCENTAGE"); setSelectedProducts([]) }}>
                                <option value="ORDER">Toda la compra</option>
                                <option value="PRODUCT">Producto en específico</option>
                            </Form.Select>
                        </Form.Group>


                        {/* Tipo de descuento */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Tipo de descuento</Form.Label>
                            <Form.Select name="discount_type" onChange={(e) => { setDiscountType(e.target.value); }}>
                                <option value="PERCENTAGE">Porcentaje</option>
                                <option value="FIXED">Monto</option>
                                <RenderIf isTrue={cuponType === "PRODUCT"}>
                                    <option value="FREE_PRODUCT">Producto gratis</option>
                                </RenderIf>
                            </Form.Select>
                        </Form.Group>

                        <RenderIf isTrue={cuponType === 'ORDER'}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Valor mínimo de la orden</Form.Label>
                                <Form.Control type="text" placeholder="0.00" name="min_order_value" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Máximo descuento</Form.Label>
                                <Form.Control type="text" placeholder="0.00" name="max_discount" />
                            </Form.Group>
                        </RenderIf>

                        <RenderIf isTrue={cuponType === 'PRODUCT'}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold' onChange={e => {
                                    setSelectedProducts(...{ combo_id: e.target.value, is_combo: 1 })
                                }}>Combos</Form.Label>
                                <Form.Select>
                                    <option value={null}>Ninguno seleccionado</option>
                                    {combos.map((combo) => (

                                        <option key={combo.id} value={combo.id}>{combo.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold' onChange={e => {
                                    setSelectedProducts(...{ group_id: e.target.value, is_combo: 0 })
                                }}>Productos</Form.Label>
                                <Form.Select>
                                    <option value={null}>Ninguno seleccionado</option>
                                    {groups.map((group) => (
                                        <option key={group.group_id} value={group.group_id}>{group.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </RenderIf>

                        {/* Valor */}
                        <RenderIf isTrue={discount_type !== "FREE_PRODUCT"}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Valor</Form.Label>
                                <Form.Control type="text" placeholder="0.00" name="discount_value" />
                            </Form.Group>
                        </RenderIf>

                        {/* Opciones adicionales */}
                        <Form.Check
                            type="checkbox"
                            label="¿Fecha límite?"
                            onChange={(e) => setShowDateLimit(e.target.checked)}
                        />
                        <RenderIf isTrue={showDateLimit}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Fecha de expiración</Form.Label>
                                <Form.Control type="date" name="expiration_date" />
                            </Form.Group>
                        </RenderIf>

                        <Form.Check
                            type="checkbox"
                            label="¿Horas específicas?"
                            onChange={(e) => setShowSpecificHours(e.target.checked)}
                        />
                        <RenderIf isTrue={showSpecificHours}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Hora de inicio</Form.Label>
                                <Form.Control type="time" name="initial_hour" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Hora de finalización</Form.Label>
                                <Form.Control type="time" name="expiration_hour" />
                            </Form.Group>
                        </RenderIf>

                        <Form.Check
                            type="checkbox"
                            label="¿Días específicos?"
                            onChange={(e) => setShowSpecificDays(e.target.checked)}
                        />
                        <RenderIf isTrue={showSpecificDays}>
                            <div>
                                {['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'].map((day) => (
                                    <Form.Check
                                        key={day}
                                        type="checkbox"
                                        label={day}
                                        onChange={() => handleDaySelection(day)}
                                    />
                                ))}
                            </div>
                        </RenderIf>

                        {/* Límite de uso */}
                        <Form.Check
                            type="checkbox"
                            label="¿Límite de uso?"
                            onChange={(e) => setHasUsageLimit(e.target.checked)}
                        />
                        <RenderIf isTrue={hasUsageLimit}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Cantidad máxima de usos</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    value={usageLimit}
                                    onChange={(e) => setUsageLimit(e.target.value)}
                                    name="usage_limit"
                                />
                            </Form.Group>
                        </RenderIf>

                        {/* Códigos únicos */}
                        <Form.Check
                            type="checkbox"
                            label="¿Códigos únicos?"
                            onChange={(e) => setNeedsUniqueCodes(e.target.checked)}
                        />
                        <RenderIf isTrue={needsUniqueCodes}>
                            <Form.Group className="mb-3">
                                <Form.Label className='text-black fw-semibold'>Cantidad de códigos únicos</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    value={uniqueCodeQuantity}
                                    onChange={(e) => setUniqueCodeQuantity(e.target.value)}
                                    name="code_count"
                                />
                            </Form.Group>
                        </RenderIf>

                        {/* Descripcion */}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-black fw-semibold'>Descripcion</Form.Label>
                            <Form.Control type="texarea" placeholder="Descripcion del cupon" name="description" />
                        </Form.Group>

                        {/* Botón de guardar */}
                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalCreateCupon;
