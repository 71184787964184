import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeModal = ({ textToEncode, showModal, setShowModal }) => {
  const qrCanvasRef = useRef(null); // Ref to QRCodeCanvas

  // Function to download the qr
  const downloadQRCode = () => {
    if (qrCanvasRef.current) {
      const canvas = qrCanvasRef.current;
      const url = canvas.toDataURL("image/png"); 
      const link = document.createElement("a");
      link.href = url;
      link.download = "qr-code.png";
      link.click(); 
    } else {
      console.error("QR code canvas no encontrado.");
    }
  };

  return (
    <div>
      {/* Modal of react-bootstrap */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>QR Generado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* canvas of qr */}
          <div className="text-center">
            <QRCodeCanvas
              value={textToEncode}
              size={256}
              ref={qrCanvasRef} 
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={downloadQRCode}>
            Descargar QR
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QRCodeModal;