//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Components 
import Navbar from '../NavBar/Navbar';
import TransactionHistory from "../transactions/TransactionHistory";
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import Modal from 'react-bootstrap/Modal';
//Internal components 
import ReportTable from '../reports/table/ReportTable';
import OrderDetails from '../Orders/OrderDetails';

//External modules 
import moment from 'moment-timezone';

//API
import { OrdersFetchAPI } from '../../api/Orders';
import { checkUserPermission } from '../utils/CheckUserPermitions';
//img
import empty from '../img/icons/empty.png'

import totals from '../img/icons/total.png'

//Utils 
import { RenderIf } from '../utils/RenderIf';
//CSS
import './analitics.css'


function AnaliticStore() {
  //Params
  let { business_code } = useParams();
  //We get the user data 
  let user = localStorage.getItem('user') !== '' || localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : '';
  let token = localStorage.getItem('x-access-token')
  //States
  const [branchOrdersData, setBranchOrdersData] = useState([]);
  const [branchAnaliticsData, setBranchAnaliticsData] = useState([]);
  const [temporal, setTemporal] = useState([]);
  const [minDate, setMinDate] = useState(new Date().toJSON().slice(0, 10));
  const [userBranchoffices, setUserBranchs] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({
    code: 0
  });
  const [ordersPaymentMethod, setOrdersPaymentMethod] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [detailOrderModalShow, setDetailOrderModalShow] = useState(false);
  const [detailOrder, setDetailOrder] = useState();
  const [order_type, setOrderType] = useState("temporal")
  const [reload, setReLoad] = useState(0);

  //Variables
  //We get the actual date 
  const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')
  //We put the actual date in rank of all the day 
  const maxDate = actualDate + 'T23:59:59.000+00:00';

  //Functions to show and hide the modal 
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);
  const handleCloseDetailOrderModal = () => setDetailOrderModalShow(false);
  const handleShowDetailOrderModal = () => setDetailOrderModalShow(true);


  //Function to gen the empty card to the transaction history 
  const EmptyCard = () => {
    return (
      <div className="m-3 w-auto ">
        <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
          <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
            <img src={empty} alt="Vacio" width="35px" height="35px" />
            <div className='text-center mt-3'>
              <h5>Vacío</h5>
              <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //Function to populate the list orders 
  const listOrders = () => {

    const listOrders = branchOrdersData.map((data) => (
      <>
        <div className='col-12 col-md-4 col-lg-3 col-xl-3' onClick={async () => {
          await getOrderById(data._id);
          //We show the detail modal 
          handleShowDetailOrderModal()
        }}>
          <TransactionHistory data={data} />
        </div>
      </>
    ));
    //We check if the list orders has data 
    if (listOrders.length === 0) {
      return EmptyCard()
    } else {
      return (listOrders)
    }
  }


  //Function to get order by id (order_id)
  const getOrderById = async (order_id) => {
    try {
      const response = await OrdersFetchAPI.getOrderById(order_id);
      setDetailOrder(response.data.order)
      setOrderType("permanent")
    } catch (err) {
      setDetailOrder({})
      setOrderType("permanent")
      console.log(err)
    }
  }

  //Function to populate the order details (modal)
  const detailOrderList = () => {
    return detailOrder === undefined ? "" : <OrderDetails orderData={detailOrder} order_type={order_type} business_data={user.business} setLoad={setReLoad} />
  }

  //We get the transactions by branchoffice 
  const getTransactionsByBranchOffice = async (business_code, branchoffice_code, minDate, maxDate) => {
    try {
      //We show the loading data alert 
      //LoadingAlert()
      //We call the orders of the branchoffice 
      const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, branchoffice_code, minDate, maxDate, 0, user.id, token);
      setBranchOrdersData(response.data.orders);
      setTemporal(response.data.orders);
      setBranchAnaliticsData([response.data.analitics]);
    } catch (err) {
      //We show the error alert 
      ErrorAlert('No tiene información')
      console.log(err);
    }
  }

  //Function to search any result on the search filter 
  function handleChangeFiltro(event) {
    let resultFilter = [];
    if (event.target.value == "") {
      console.log(event.target.value);
      setBranchOrdersData(temporal);
    } else {
      for (
        let recorrer = 0;
        recorrer < branchOrdersData.length;
        recorrer++
      ) {
        const examinar = branchOrdersData[recorrer];

        const info = JSON.stringify(examinar);
        const resultado = info
          .toLowerCase()
          .includes(event.target.value.toLowerCase());

        if (resultado == true) {
          resultFilter.push(examinar);

          setBranchOrdersData(resultFilter);
          console.log(resultFilter);
        }
      }
    }
  }


  //Fucntion to populate cards count 
  const CardsCounts = (data) => {
    const getdata = data.filter((item) => {
      // Verificar permisos específicos para cada método de pago
      const permissionKey = `view_total_${item.name.toLowerCase()}`; // Generar la clave de permiso dinámica
      return checkUserPermission(permissionKey, user.permissions) !== -1;
    })
    const content = getdata.map((data) => (
      <>
        <div className="col-12 col-md-4 col-lg-4 mb-2">
          <div className="card-analitics-sp-sm shadow-sm"
            title='Ver Más'
            onClick={e => { setModalShow(true); getPaymentMethodOrders(data.paymentmethod_id) }}>
            <div className="card-analitics-sp-header-sm d-flex justify-content-between">
              <span className='text-muted'>{data.name}</span>
              <span>
                <RenderIf isTrue={data.name.toLowerCase() === 'visa'}>
                  <i className="fa-brands fa-cc-visa text-primary fa-3x"></i>
                </RenderIf>
                <RenderIf isTrue={(data.name.toLowerCase() === 'yappy' || data.name.toLowerCase() === 'yappy qr')}>
                  <i className="fa-solid fa-comments text-secondary fa-3x"></i>
                </RenderIf>
                <RenderIf isTrue={data.name.toLowerCase() === 'mastercard'}>
                  <i className="fa-brands fa-cc-mastercard text-warning fa-3x"></i>
                </RenderIf>
                <RenderIf isTrue={data.name.toLowerCase() === 'clave'}>
                  <i className="fa-solid fa-credit-card text-danger fa-3x"></i>
                </RenderIf>
                <RenderIf isTrue={data.name.toLowerCase() === 'efectivo'}>
                  <i className="fa-solid fa-sack-dollar text-success fa-3x"></i>
                </RenderIf>
                <RenderIf isTrue={data.name.toLowerCase() === 'amex'}>
                  <i className="fa-brands fa-cc-amex text-info fa-3x"></i>
                </RenderIf>
                <RenderIf isTrue={(data.name.toLowerCase() === 'nota de credito' || data.name.toLowerCase() === 'credito')}>
                  <i className="fa-solid fa-clipboard text-secondary fa-3x"></i>
                </RenderIf>
              </span>
            </div>
            <span className="card-value-total fw-bold text-dark"> <span>$</span>
              {(!data.total) ? <>0.00</> : <> {parseFloat(data.total).toFixed(2)}</>}
            </span>
          </div>
        </div>
      </>
    ))
    return (
      <>
        {content}
      </>
    )
  }

  //Fucntion to populate cards bra 
  const branchofficeCards = (data) => {
    const getdata = data;
    const content = getdata.map((data) => (
      <>
        <div className="col-6 col-sm-6 col-md-3 col-xl-3 mb-4">
          <div className="card widget-card border shadow-sm rounded-4">
            <div className="card-body p-4">
              <div className="row">
                <div className="col-8">
                  <h5 className="card-title fw-bold mb-3">{data.branchoffice_name}</h5>
                  <h4 className="card-subtitle text-body-secondary m-0">${parseFloat(data.total).toFixed(2)}</h4>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-end">
                    <div className="lh-1 text-white bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-building"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </>
    ))
    return (
      <>
        {content}
      </>
    )
  }


  //Function to get the payment method orders 
  const getPaymentMethodOrders = async (paymentmethod_id) => {
    try {
      //We call the orders of the branchoffice 
      const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethod(business_code, selectedBranch.code, minDate, maxDate, paymentmethod_id, token);
      console.log(response)
      setOrdersPaymentMethod(response.data.orders)
    } catch (err) {
      console.log(err)
    }
  }

  //Function to populate the repor table modal 
  const reportDetail = (general_sales) => {
    return <ReportTable table_name="Tabla de ventas detalladas" table_headers={["Fecha", "Hora", "Orden", "Total", "Subtotal", "Itbms", "Descuento", "Cufe"]} table_type="paymentmethod_report" table_data={general_sales} />

  }

  //Function to view analitics 
  const viewAnalitics = () => {

    const content = branchAnaliticsData.map((data) => (
      <>
        {/** Get totals */}
        <RenderIf isTrue={checkUserPermission('get_totals', user.permissions) !== -1}>
          <h4 className='text-dark fw-bold'> Totales  </h4>
          <div className='row gap-0 g-lg-0'>
            <div className="col-12 col-md-4 col-lg-4">          <div className="card-analitics-sp shadow-sm">
              <div className="card-analitics-sp-header d-flex justify-content-between">
                <span>Total de Ventas</span>
                <span>{minDate}</span>
              </div>

              <span className="card-value-total fw-bold text-color-sp"> <span>$</span>
                {(!data.totalSales) ? <>0.00</> : <>{parseFloat(data.totalSales).toFixed(2)}</>}</span>


            </div></div>
            {/* 2 */}
            <div className="col-12 col-md-4 col-lg-4">          <div className="card-analitics-sp shadow-sm">
              <div className="card-analitics-sp-header d-flex justify-content-between">
                <span>Total Neto</span>
                <span>
                  <img src={totals} alt="Totals" className='icon-img' />
                </span>
              </div>

              <span className="card-value-total fw-bold text-color-sp"> <span>$</span>

                {(!data.subTotalSales) ? <>0.00</> : <>{parseFloat(data.subTotalSales).toFixed(2)}</>}
              </span>

            </div>
            </div>

            {/* 3 */}
            <div className="col-12 col-md-4 col-lg-4">
              <div className="card-analitics-sp shadow-sm">
                <div className="card-analitics-sp-header d-flex justify-content-between">
                  <span>Impuestos</span>
                  <span><i className="uil uil-percentage icon-card"></i></span>
                </div>

                <span className="card-value-total fw-bold text-color-sp"> <span>$</span>
                  {(!data.totalTaxSales) ? <>0.00</> : <> {parseFloat(data.totalTaxSales).toFixed(2)}</>}
                </span>
              </div>
            </div>
          </div>
        </RenderIf>




        {/* <!-- 2 --> */}
        <div className="mt-lg-3 ">
          <div className="row mt-2 border-sp pb-4 bg-white">
            <h4 className='text-dark fw-bold'> Métodos de Pagos  </h4>
            <div className="main-content">
              <div className="row  ">
                {CardsCounts(data.paymenthods)}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- 2 --> */}
        <RenderIf isTrue={checkUserPermission('get_branch_totals', user.permissions) !== -1}>
          <div className="mt-lg-3 ">
            <div className="row mt-2 border-sp pb-4 bg-white">
              <h4 className='text-dark fw-bold'> Sucursales  </h4>
              <div className="main-content">
                <div className="row">
                  {branchofficeCards(data.branchofficeTotals)}
                </div>
              </div>
            </div>
          </div>
        </RenderIf>
      </>

    ));
    return (<>{content}</>)
  }

  //Function to populate the users branch 
  const branchList = () => {
    const listBracnh = userBranchoffices.map((branch) => (
      <>
        <option value={branch.code}>{branch.branchoffice_name}</option>
      </>
    ));
    return listBracnh
  }

  //Hook to charge data when the page is loaded 
  useEffect(() => {
    (async () => {
      try {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We search the branch orders 
        getTransactionsByBranchOffice(business_code, selectedBranch.code, actualDate, maxDate)
      } catch (err) {
        console.log(err);
      }
    })();
    setReLoad(0)
  }, [reload]);


  return (
    <>
      <div className='sp-grid bg-white no-scroll position-relative g-0'>
        <div className=' fixed-column'>
          <Navbar titlePage="Analiticas" />
        </div>
        <div className='other-column '>
        </div>
        <main className="px-lg-2 px-1 mx-1 rounded-4 bg-body position-relative">
          <section className='rounded-4 mt-1 bg-section-analitics p-4 '>
            <div className="text-center  ">
              <div className="container py-1 rounded-4">
                <h2 className="h1 banner-reports-title fw-bold text-white ">Analíticas</h2>
                <p className='m-0 p-0 banner-reports-description'>Visualización de datos que abarca métodos de pago, sucursales y transacciones.</p>
              </div>
            </div>

            <section className=' d-lg-flex justify-content-evenly align-items-center mb-2 rounded-4 p-2 w-100 '>
              <div className='row row-cols-lg-auto g-3 align-items-center'>
                <div className="col-12">
                  <h6 className='fw-bold text-white'>Sucursal</h6>
                  <select onChange={e => setSelectedBranch({ code: e.target.value })} className='form-select' name="user_branch" id="user_branch">
                    <option value={0}>Mostrar todos</option>
                    {branchList()}
                  </select>
                </div>

                <div className="col-12">
                  <h6 className='fw-bold text-white'>Fecha</h6>
                  <div className="login-form needs-validation">
                    <input
                      onChange={(e) => setMinDate(e.target.value)}
                      type="date" name="date" id="" className="form-control" />
                  </div>
                </div>

                <div className="col-12">
                  <button className="btn btn-success rounded mt-3 w-100"
                    onClick={e => getTransactionsByBranchOffice(business_code, selectedBranch.code, minDate, `${minDate}T23:59:59.000+00:00`)}
                    id="btn-analitycs">
                    Aceptar
                  </button></div>
              </div>
            </section>

          </section>




          <div className="row justify-content-center mt-3">
            <div className=' position-relative'>
              {viewAnalitics()}
            </div>
            <div className=" ">

              <div className='d-flex justify-content-between align-items-center p-2 border rounded-3 bg-primary-subtle mx-3'>
                <h4 className='text-primary fw-bold'>  Historial de transacciones  </h4>
                <div className="d-flex justify-content-between align-items-center">
                  {/* <h6 className="mb-0 text-secondary fw-bold">Buscador</h6> */}
                  <div className='border-0 fw-bold fs-3'>
                    <input
                      spellcheck="false"
                      className="form-control"
                      type="text"
                      step="any"
                      onChange={handleChangeFiltro}
                      placeholder="Buscar orden "
                    />

                  </div>
                </div>
              </div>
            </div>
            {/** We get the list of the order */}
            <RenderIf isTrue={ checkUserPermission("transaction_list", user.permissions) !== -1}>
              <div className='row pb-5 '>
                {listOrders()}
              </div>
            </RenderIf>
          </div>
        </main>
      </div>


      {/* Modal of report details orders (table) payment methods*/}
      <Modal size='xl' show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportDetail(ordersPaymentMethod)}
        </Modal.Body>
      </Modal>

      <Modal className="modal" show={detailOrderModalShow} onHide={handleCloseDetailOrderModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailOrderList()}
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AnaliticStore